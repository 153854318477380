import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { staff as staffModule } from 'redux/modules'
import { useParams, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import PROP_TYPES from '../../constants/propTypes'
import urlConstructor from '../../utils/urlConstructor'
import { TEAM_ACCESS_ROUTE } from '../../constants/routes'
import { StaffLoader } from '../../loaders'
import Form from './components/Form'

// @todo: #staff #roles check permissions
const StaffFormContainer = ({
  onCreate,
  onUpdate,
  onDelete,
  current,
  isLoading,
  history,
  handleClose,
  initialValues,
  currentClubId,
}) => {
  const { teamId, seasonId } = useParams()
  const staffRoute = urlConstructor(teamId, seasonId, TEAM_ACCESS_ROUTE)

  const handleCreate = (data) => {
    onCreate(teamId, data).then(() => {
      handleClose()
    })
  }

  const handleUpdate = (data) => {
    onUpdate(current.id, data).then(() => {
      handleClose()
    })
  }

  const handleDelete = () => {
    onDelete(current.id).then(() => {
      history.push(staffRoute)
    })
  }

  const handleCancel = () => {
    history.push(staffRoute)
  }
  const staffId = initialValues?.id

  return (
    <>
      {staffId ? (
        <StaffLoader staffId={staffId}>
          <Form
            onSubmit={handleUpdate}
            onDelete={handleDelete}
            onCancel={handleCancel}
            isLoading={isLoading}
            currentClubId={currentClubId}
            initialValues={
              !initialValues
                ? {
                    first_name: current.first_name,
                    last_name: current.last_name,
                    email: current.email,
                    position: current.position,
                    role_uuid: current.user_with_role?.role?.uuid,
                  }
                : {
                    ...initialValues,
                    role_uuid: current.user_with_role?.role?.uuid,
                  }
            }
            handleClose={handleClose}
          />
        </StaffLoader>
      ) : (
        <Form
          onSubmit={handleCreate}
          onCancel={handleClose}
          currentClubId={currentClubId}
          isLoading={isLoading}
          handleClose={handleClose}
        />
      )}
    </>
  )
}

StaffFormContainer.defaultProps = {
  current: {},
}

StaffFormContainer.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  current: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    role_uuid: PropTypes.string,
  }),
  currentClubId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  history: PROP_TYPES.history.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ staff, clubs }) => ({
      isLoading: staff.isLoading,
      current: staff.current,
      currentClubId: clubs.current.id,
    }),
    {
      fetchStaff: staffModule.fetchStaff,
      onCreate: staffModule.createStaff,
      onUpdate: staffModule.updateStaff,
      onDelete: staffModule.deleteStaff,
    }
  )
)(StaffFormContainer)
