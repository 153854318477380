import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { Box, Button, Typography } from '@material-ui/core'
import { DataGrid } from '@mui/x-data-grid'
import { staff as staffModule } from 'redux/modules'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import useStyles from '../styled'
import { staffColumns, SORT_NAME } from '../Constants'
import StaffCreateDialog from './StaffCreateDialog'

const StaffAccess = ({
  allStaff,
  handleClick,
  deleteStaff,
  theme,
}) => {
  const classes = useStyles(theme)
  const [sortModel, setSortModel] = useState(SORT_NAME)
  const [open, setOpen] = useState(false)

  const staffRows = allStaff.map((staff) => {
    return {
      id: staff.id,
      uuid: staff.uuid,
      roleUuid: staff.user_with_role ? staff.user_with_role.uuid : null,
      first_name: staff.first_name,
      last_name: staff.last_name,
      firstName: `${staff.first_name} ${staff.last_name}`,
      email: staff.assigned_user?.email,
      position: staff.position,
      role: _.startCase(_.toLower(staff.user_with_role.role.name)),
      userRoleId: staff.user_with_role?.id,
      deleteStaff,
      handleClick,
    }
  })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box>
      <Box mb={3}>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          <Typography>
            <Translate id="team.add-staff" />
          </Typography>
        </Button>
      </Box>

      {open && <StaffCreateDialog open={open} onClose={handleClose} />}
      <Box className={classes.root}>
        <DataGrid
          className={classes.table}
          rows={staffRows}
          columns={staffColumns}
          pageSize={100}
          rowHeight={60}
          headerHeight={45}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          disableColumnFilter
          hideFooter
          disableColumnSelector
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
        />
      </Box>
    </Box>
  )
}

StaffAccess.defaultProps = {
  allStaff: [],
}

StaffAccess.propTypes = {
  handleClick: PropTypes.func.isRequired,
  allStaff: PropTypes.arrayOf(PropTypes.shape()),
  revokeUserRole: PropTypes.func.isRequired,
  deleteStaff: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ team }) => ({
      team,
    }),
    {
      deleteStaff: staffModule.deleteStaff,
    }
  )
)(StaffAccess)
