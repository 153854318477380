import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Joi from 'joi'
import { Translate } from 'react-localize-redux'
import { Field, reduxForm } from 'redux-form'
import { Button, CardActions, Grid, MenuItem } from '@material-ui/core'
import { Card, Input } from '../../../components'
import SelectList from 'components/inputes/SelectList'
import createJoiValidator from '../../../utils/createJoiValidator'

const validationSchema = Joi.object({
  first_name: Joi.string().required(),
  last_name: Joi.string().required(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  position: Joi.string().required(),
  role_uuid: Joi.string().required(),
})

const formatRoleName = (name) => {
  return _.startCase(_.toLower(name))
}
const allowedStaffRoles = JSON.parse(process.env.REACT_APP_ALLOWED_STAFF_ROLES)
const cfgClubId = +process.env.REACT_APP_CFG_CLUB_ID

const StaffForm = ({
  handleSubmit,
  initialValues,
  handleClose,
  isLoading,
  currentClubId,
}) => {
  // Filter the roles array to keep only the "ground_coach" role if the current club ID matches
  const filteredRoles = allowedStaffRoles.filter(
    (role) => role.name !== 'ground_coach' || currentClubId === cfgClubId
  )

  return (
    <Card>
      <Card.Title>
        {initialValues.id ? (
          <Translate id="team.edit-staff" />
        ) : (
          <Translate id="team.add-staff" />
        )}
      </Card.Title>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <form onSubmit={handleSubmit}>
            <Field
              name="first_name"
              label={<Translate id="signup.first_name" />}
              margin="dense"
              component={Input}
            />
            <Field
              name="last_name"
              label={<Translate id="signup.last_name" />}
              margin="dense"
              component={Input}
            />
            <Field
              name="email"
              label={<Translate id="userRoles.e-mail" />}
              margin="dense"
              disabled={!!initialValues.id}
              component={Input}
            />
            <Field
              name="position"
              label={<Translate id="userRoles.function" />}
              margin="dense"
              component={Input}
            />
            <Field
              name="role_uuid"
              label={<Translate id="userRoles.role" />}
              component={SelectList}
              disabled={!!initialValues.id}
            >
              {filteredRoles.map((role) => (
                <MenuItem key={role.uuid} value={role.uuid}>
                  {formatRoleName(role.name)}
                </MenuItem>
              ))}
            </Field>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                paddingTop: '1rem',
              }}
            >
              <CardActions>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  <Translate id="button.cancel" />
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  <Translate id="button.submit" />
                </Button>
              </CardActions>
            </div>
          </form>
        </Grid>
      </Grid>
    </Card>
  )
}
StaffForm.defaultProps = {
  initialValues: {},
}

StaffForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentClubId: PropTypes.number.isRequired,
  initialValues: PropTypes.arrayOf(PropTypes.object),
}

export default reduxForm({
  form: 'staff',
  validate: createJoiValidator(validationSchema),
})(StaffForm)
