import React, { useEffect, useState } from 'react'
import { Button, MenuItem, Box, makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { teams as teamsModule, clubs as clubsModule } from 'redux/modules'
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  Typography,
} from '@mui/material'
import SearchIcon from '@material-ui/icons/Search'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import CircularSpinner from 'components/loader/CircularSpinner'
import { Translate } from 'react-localize-redux'
import { SelectedMenu } from 'components'
import TeamListItem from './components/TeamListItem'
import Header from './components/Header'
import CreateTeamPopup from './components/CreateTeamPopup'

const useStyles = makeStyles((theme) => ({
  box: {
    background: 'white',
    margin: '0 1rem',
    padding: '2rem 3rem',
    minHeight: '100vh',
    [theme.breakpoints.down('xs')]: {
      margin: '0',
      padding: '1rem',
    },
  },
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  label: {
    marginRight: '1rem!important',
    fontWeight: '900!important',
    fontFamily: 'Helvetica !important',
    fontSize: '1rem !important',
  },
  select: (props) => ({
    marginRight: '1rem',
    borderRadius: '.5rem!important',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      borderRadius: 0,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${props.primarycolor} !important`,
    },
  }),
  addTeamBtn: (props) => ({
    borderRadius: '.7rem',
    color: 'black',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderColor: `${props.primarycolor} !important`,
    padding: '.2rem .7rem',
    whiteSpace: 'nowrap',
  }),
  formControl: {
    flex: '1',
    width: '420px',
    marginRight: 15,
    marginBottom: '0 !important',
    [theme.breakpoints.down('md')]: {
      marginRight: '1rem!important',
      width: '100%',
    },
  },
  ageClasses: {
    margin: '1.5rem 0',
  },
  ListOfTeams: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '25px',
    margin: '0 auto',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  filterButton: (props) => ({
    margin: '.5rem 2rem .5rem 0',
    borderRadius: '.7rem',
    padding: '.1rem 1.5rem',
    color: 'black',
    borderColor: props.primarycolor,
    '&:hover': {
      color: props.primarycolor,
      borderColor: props.primarycolor,
    },
    '&.selected': {
      backgroundColor: props.primarycolor,
      color: 'white',
    },
  }),
  italicText: {
    fontStyle: 'italic',
    paddingBottom: '.2rem',
    fontSize: '.8rem !important',
  },
  buttonBoxContainer: {
    display: 'flex',
  },
  sortIcon: {
    cursor: 'pointer',
  },
}))

const TeamsDashboard = ({ fetchClubs, fetchTeamsByParams }) => {
  const [clubs, setClubs] = useState([])
  const [teams, setTeams] = useState([])
  const [selectedClubUuid, setSelectedClubUuid] = useState('')
  const [teamSearchInput, setTeamSearchInput] = useState('')
  const [selectedAgeClassFilter, setSelectedAgeClassFilter] = useState(null)
  const [filteredTeams, setFilteredTeams] = useState([])
  const [sortAsc, setSortAsc] = useState(true)
  const [ageClasses, setAgeClasses] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [ageClassFilter, setAgeClassFilter] = useState(null)
  const [tags, setTags] = useState('')
  const [selectedTagFilter, setSelectedTagFilter] = useState(null)

  const selectedClubTheme = clubs.find((club) => club.uuid === selectedClubUuid)
  const selectedClub = clubs.find((club) => club.uuid === selectedClubUuid)

  let clubTheme = {}
  if (selectedClubTheme?.theme_temp) {
    try {
      clubTheme = JSON.parse(selectedClubTheme.theme_temp)
    } catch (error) {
      console.error('Error parsing JSON:', error)
    }
  }
  const commonFetchParams = {
    include: ['ageClass', 'playersCount', 'logo', 'seasons', 'teamTag'],
  }

  useEffect(() => {
    fetchClubs({ include: ['ageClasses', 'teamTags'] })
      .then((res) => {
        if (res.response.data && res.response.data.length === 0) {
          setIsLoading(false)
          setErrorMessage('No clubs available')
        } else {
          setIsLoading(true)
          setClubs(res.response.data)
          setSelectedClubUuid(res.response.data[0].uuid)
        }
      })
      .catch((error) => {
        setIsLoading(false)
        console.error('Error fetching clubs', error)
        setErrorMessage('Error fetching clubs')
      })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    if (selectedClubUuid) {
      fetchTeamsByParams({
        ...commonFetchParams,
        'filter[club.uuid]': selectedClubUuid,
      })
        .then((fetchTeamsRes) => {
          if (selectedClub) {
            setAgeClasses(selectedClub.relationships.age_classes)
            setTags(selectedClub.relationships.team_tags)
          }
          setTeams(fetchTeamsRes.response)
          setFilteredTeams(fetchTeamsRes.response)
          setTeamSearchInput('')
          setSortAsc(true)
          setErrorMessage('')
          setIsLoading(false)
          setSelectedAgeClassFilter(null)
          setSelectedTagFilter(null)
        })
        .catch((error) => {
          console.error('Error fetching teams', error)
          setErrorMessage('Error fetching teams.')
          setIsLoading(false)
        })
    }
  }, [selectedClubUuid])

  const handleSelectedClubChange = (event) => {
    setSelectedClubUuid(event.target.value)
    setSortAsc(true)
  }

  useEffect(() => {
    if (selectedClub) {
      setTags(selectedClub.relationships.team_tags)
    }
  }, [selectedClub])

  const handleTeamSearchInputChange = (event) => {
    const searchTerm = event.target.value.toLowerCase()
    setTeamSearchInput(searchTerm)

    let filtered = [...teams]
    if (selectedAgeClassFilter) {
      filtered = filtered.filter((team) => {
        return team.relationships.age_class?.uuid === selectedAgeClassFilter
      })
    }
    if (selectedTagFilter) {
      filtered = filtered.filter((team) => {
        const teamTag = team.relationships.team_tag

        return (
          teamTag &&
          [teamTag].flat().some((tag) => tag?.uuid === selectedTagFilter)
        )
      })
    }
    filtered = filtered.filter(
      (team) =>
        searchTerm === '' || team.name.toLowerCase().includes(searchTerm)
    )
    setFilteredTeams(filtered)
  }

  const handleFilterSelect = (type, id) => {
    setIsLoading(true)

    const filterValue = id === null ? null : id

    const filters = {
      ...commonFetchParams,
      'filter[club.uuid]': selectedClubUuid,
    }

    if (type === 'ageClass' && filterValue !== null) {
      filters['filter[ageClass.uuid]'] = filterValue
    }

    if (type === 'teamTag' && filterValue !== null) {
      filters['filter[teamTag.uuid]'] = filterValue
    }

    if (type === 'ageClass' && selectedTagFilter) {
      filters['filter[teamTag.uuid]'] = selectedTagFilter
    }

    if (type === 'teamTag' && selectedAgeClassFilter) {
      filters['filter[ageClass.uuid]'] = selectedAgeClassFilter
    }

    fetchTeamsByParams(filters)
      .then((fetchTeamsRes) => {
        setTeams(fetchTeamsRes.response)
        setFilteredTeams(fetchTeamsRes.response)
        setSortAsc(true)
        setErrorMessage('')
        setIsLoading(false)

        if (type === 'ageClass') {
          setSelectedAgeClassFilter(filterValue)
          setAgeClassFilter(id)
        }

        if (type === 'teamTag') {
          setSelectedTagFilter(filterValue)
        }
      })
      .catch((error) => {
        console.error('Error fetching teams', error)
        setErrorMessage('Error fetching teams.')
        setIsLoading(false)
      })
  }

  const handleSortIconClick = () => {
    setIsLoading(true)

    const sortingCriteria = sortAsc ? '-name' : 'name'
    const filters = {
      ...commonFetchParams,
      'filter[club.uuid]': selectedClubUuid,
      sort: sortingCriteria,
    }

    if (selectedAgeClassFilter !== null) {
      filters['filter[ageClass.uuid]'] = selectedAgeClassFilter
    }

    if (selectedTagFilter) {
      filters['filter[teamTag.uuid]'] = selectedTagFilter
    }

    fetchTeamsByParams(filters)
      .then((fetchTeamsRes) => {
        setFilteredTeams(fetchTeamsRes.response)
        setErrorMessage('')
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching teams', error)
        setErrorMessage('Error fetching teams.')
        setIsLoading(false)
      })
    setSortAsc(!sortAsc)
  }

  const handleTeamModalOpen = () => {
    setOpen(true)
  }

  const handleTeamModalClose = () => {
    setOpen(false)
  }

  const userHasCreateTeamPermission = clubs
    .find((club) => club.uuid === selectedClubUuid)
    ?.can.includes('create-team')

  const classes = useStyles(clubTheme)

  return (
    <>
      <Header clubTheme={clubTheme} />
      <Box className={classes.box}>
        <Box className={classes.paper}>
          <Box className={classes.wrapper}>
            {clubs.length > 1 && (
              <>
                <Typography noWrap className={classes.label}>
                  <Translate id="myteams.clubs" />
                </Typography>
                <SelectedMenu
                  id="clubs"
                  value={selectedClubUuid}
                  className={classes.formControl}
                  onChange={handleSelectedClubChange}
                  label={<Translate id="myteams.clubs" />}
                >
                  {clubs &&
                    clubs.map((club) => (
                      <MenuItem key={club.uuid} value={club.uuid}>
                        {club.name}
                      </MenuItem>
                    ))}
                </SelectedMenu>
              </>
            )}
            <Typography noWrap className={classes.label}>
              <Translate id="myteams.teams" />
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <OutlinedInput
                variant="outlined"
                className={classes.select}
                value={teamSearchInput}
                onChange={handleTeamSearchInputChange}
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            {sortAsc ? (
              <ArrowDownwardIcon
                onClick={handleSortIconClick}
                className={classes.sortIcon}
              />
            ) : (
              <ArrowUpwardIcon
                onClick={handleSortIconClick}
                className={classes.sortIcon}
              />
            )}
          </Box>
          <Box className={classes.buttonBoxContainer}>
            <CreateTeamPopup
              handleClose={handleTeamModalClose}
              open={open}
              selectedClubUuid={selectedClubUuid}
              ageClasses={ageClasses}
              setTeams={setTeams}
              setFilteredTeams={setFilteredTeams}
              commonFetchParams={commonFetchParams}
              selectedAgeClassFilter={selectedAgeClassFilter}
              selectedClub={selectedClub}
              setClubs={setClubs}
              selectedTagFilter={selectedTagFilter}
              setSelectedAgeClassFilter={setSelectedAgeClassFilter}
              setSelectedTagFilter={setSelectedTagFilter}
            />
            {userHasCreateTeamPermission && (
              <Button
                variant="outlined"
                color="primary"
                className={classes.addTeamBtn}
                onClick={handleTeamModalOpen}
              >
                <Translate id="myteams.add-team" />
              </Button>
            )}
          </Box>
        </Box>
        <Box className={classes.ageClasses}>
          <Typography className={classes.italicText}>
            <Translate id="myteams.select-age-class" />
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            className={`${classes.filterButton} ${
              selectedAgeClassFilter === null ? 'selected' : ''
            }`}
            onClick={() => handleFilterSelect('ageClass', null)}
          >
            <Translate id="team.all-teams" />
          </Button>
          {ageClasses &&
            ageClasses.map((ageClass) => (
              <Button
                key={ageClass.uuid}
                variant="outlined"
                color="primary"
                value={ageClass.uuid}
                className={`${classes.filterButton} ${
                  selectedAgeClassFilter === ageClass.uuid ? 'selected' : ''
                }`}
                onClick={() => handleFilterSelect('ageClass', ageClass.uuid)}
              >
                {ageClass.name}
              </Button>
            ))}
        </Box>
        {selectedClub && !!selectedClub.enable_team_tags && (
          <Box className={classes.ageClasses}>
            <Typography className={classes.italicText}>
              <Translate id="myteams.select-tag" />
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              className={`${classes.filterButton} ${
                selectedTagFilter === null ? 'selected' : ''
              }`}
              onClick={() => handleFilterSelect('teamTag', null)}
            >
              <Translate id="team.all-teams" />
            </Button>
            {tags &&
              tags.map((tag) => (
                <Button
                  value={tag.uuid}
                  key={tag.uuid}
                  variant="outlined"
                  color="primary"
                  className={`${classes.filterButton} ${
                    selectedTagFilter === tag.uuid ? 'selected' : ''
                  }`}
                  onClick={() => handleFilterSelect('teamTag', tag.uuid)}
                >
                  {tag.name}
                </Button>
              ))}
          </Box>
        )}
        <Box>
          {isLoading && <CircularSpinner />}
          {errorMessage && (
            <Typography variant="body1" color="error">
              {errorMessage}
            </Typography>
          )}
          {!isLoading && !errorMessage && filteredTeams.length === 0 && (
            <Typography variant="body1">
              <Translate id="myteams.no-teams-match-filter" />
            </Typography>
          )}
          <Box className={classes.ListOfTeams}>
            {!isLoading &&
              !errorMessage &&
              filteredTeams.length > 0 &&
              filteredTeams.map((team) => (
                <TeamListItem
                  key={team.uuid}
                  team={team}
                  clubTheme={clubTheme}
                  selectedClub={selectedClub}
                />
              ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}

TeamsDashboard.propTypes = {
  fetchClubs: PropTypes.func.isRequired,
  fetchTeamsByParams: PropTypes.func.isRequired,
}

export default connect(null, {
  fetchClubs: clubsModule.fetchClubs,
  fetchTeamsByParams: teamsModule.fetchTeamsByParams,
})(TeamsDashboard)
