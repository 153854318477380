import React from 'react'
import {
  Card,
  Avatar,
  Chip,
  CardContent,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PROP_TYPES from 'constants/propTypes'

const useStyles = makeStyles((theme) => ({
  removePlayerIcon: {
    position: 'absolute',
    right: 0,
    top: -8,
    padding: 0,
    zIndex: 1,
    backgroundColor: `${theme.palette.common.white}`,
    border: '1px solid',
    '&:hover': {
      backgroundColor: `${theme.palette.common.white}`,
    },
  },
  smallAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  playerFieldCard: {
    backgroundColor: 'unset',
    textAlign: 'center',
    maxWidth: '120px',
    boxShadow: 'unset',
    cursor: 'pointer',
    '& .MuiCardContent-root': {
      display: 'grid',
      padding: '0px',
    },
    '& .MuiCardActions-root': {
      padding: '0px',
    },
    '& .MuiAvatar-root': {
      justifySelf: 'center',
    },
    '& .MuiButton-root': {
      minWidth: '45px',
    },
    '& .MuiButton-outlinedSizeSmall': {
      fontSize: '.7rem',
      height: 21.6,
      width: 49.6,
      padding: '0px 5px',
      backgroundColor: `${theme.palette.common.white}`,
    },
    '& .MuiButton-endIcon': {
      marginLeft: '2px',
    },
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '1rem',
    },
    '& .MuiChip-sizeSmall': {
      height: '18px',
      backgroundColor: `${theme.palette.common.white}`,
      color: `${theme.palette.common.black}`,
    },
  },
}))

const PlayerFieldCard = ({ handleClearPosition, position }) => {
  const classes = useStyles()

  return (
    <Card className={classes.playerFieldCard}>
      <CardContent>
        <IconButton
          aria-label="close"
          className={classes.removePlayerIcon}
          onClick={() => handleClearPosition(position)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        {position.relationships?.player.relationships && (
          <Avatar
            src={position.relationships.player.relationships.avatar?.full_url}
            className={classes.smallAvatar}
          >
            {position.relationships?.player.last_name
              ? position.relationships?.player.last_name.charAt(0)
              : position.relationships?.player.first_name?.charAt(0)}
          </Avatar>
        )}
        <Chip
          label={
            position.relationships?.player.last_name ||
            position.relationships?.player.first_name
          }
        />
      </CardContent>
    </Card>
  )
}

PlayerFieldCard.propTypes = {
  position: PROP_TYPES.shape().isRequired,
  handleClearPosition: PROP_TYPES.func.isRequired,
}
export default PlayerFieldCard
