import { handleActions, combineActions } from 'redux-actions'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'FAVORITES'

const FETCH_FAVORITES_ARTICLES = actionsTypesGenerator(
  `FETCH_${namespace}_ARTICLES`
)
const FETCH_FAVORITES_DRILLS = actionsTypesGenerator(
  `FETCH_${namespace}_DRILLS`
)
const FETCH_FAVORITES_TRAINING_PACKAGES = actionsTypesGenerator(
  `FETCH_${namespace}_TRAINING_PACKAGES`
)
const FETCH_FAVORITES_VIDEOS = actionsTypesGenerator(
  `FETCH_${namespace}_VIDEOS`
)
const FETCH_FAVORITES_SEQUENCES = actionsTypesGenerator(
  `FETCH_${namespace}_SEQUENCES`
)

const ADD_ARTICLE_TO_FAVORITES = actionsTypesGenerator(
  `ADD_ARTICLE_TO_${namespace}`
)
const ADD_DRILL_TO_FAVORITES = actionsTypesGenerator(
  `ADD_DRILL_TO_${namespace}`
)
const ADD_TRAINING_PACKAGE_TO_FAVORITES = actionsTypesGenerator(
  `ADD_TRAINING_PACKAGE_TO_${namespace}`
)
const ADD_VIDEO_TO_FAVORITES = actionsTypesGenerator(
  `ADD_VIDEO_TO_${namespace}`
)
const ADD_SEQUENCE_TO_FAVORITES = actionsTypesGenerator(
  `ADD_SEQUENCE_TO_${namespace}`
)

const DELETE_ARTICLE_FROM_FAVORITES = actionsTypesGenerator(
  `DELETE_ARTICLE_FROM_${namespace}`
)
const DELETE_DRILL_FROM_FAVORITES = actionsTypesGenerator(
  `DELETE_DRILL_FROM_${namespace}`
)
const DELETE_TRAINING_PACKAGE_FROM_FAVORITES = actionsTypesGenerator(
  `DELETE_TRAINING_PACKAGE_FROM_${namespace}`
)
const DELETE_VIDEO_FROM_FAVORITES = actionsTypesGenerator(
  `DELETE_VIDEO_FROM_${namespace}`
)
const DELETE_SEQUENCE_FROM_FAVORITES = actionsTypesGenerator(
  `DELETE_SEQUENCE_FROM_${namespace}`
)
export const TOGGLE_DRILL_FAVORITE = 'TOGGLE_DRILL_FAVORITE';

export const fetchFavoritesArticles = params => ({
  types: FETCH_FAVORITES_ARTICLES,
  callAPI: {
    method: 'GET',
    entity: 'Favorites Articles',
    path: '/favorites/articles',
    params,
  },
  payload: {
    entity: 'articles',
  },
})

export const fetchFavoritesDrills = params => ({
  types: FETCH_FAVORITES_DRILLS,
  callAPI: {
    method: 'GET',
    entity: 'Favorites Drills',
    path: '/favorites/drills',
    params,
  },
  payload: {
    entity: 'drills',
  },
})

export const fetchFavoritesTrainingPackages = params => ({
  types: FETCH_FAVORITES_TRAINING_PACKAGES,
  callAPI: {
    method: 'GET',
    entity: 'Favorites Training Packages',
    path: '/favorites/training_packages',
    params,
  },
  payload: {
    entity: 'trainingPackages',
  },
})

export const fetchFavoritesVideos = params => ({
  types: FETCH_FAVORITES_VIDEOS,
  callAPI: {
    method: 'GET',
    entity: 'Favorites Videos',
    path: '/favorites/videos',
    params,
  },
  payload: {
    entity: 'videos',
  },
})

export const fetchFavoritesSequences = () => ({
  types: FETCH_FAVORITES_SEQUENCES,
  callAPI: {
    method: 'GET',
    entity: 'Favorites Sequences',
    path: '/favorites/sequences',
  },
  payload: {
    entity: 'sequences',
  },
})

export const addArticleToFavorites = id => ({
  types: ADD_ARTICLE_TO_FAVORITES,
  callAPI: {
    method: 'POST',
    entity: 'Favorite Article',
    path: `/favorites/articles/${id}`,
  },
  payload: {
    entity: 'articles',
  },
})

export const addDrillToFavorites = id => ({
  types: ADD_DRILL_TO_FAVORITES,
  callAPI: {
    method: 'POST',
    entity: 'Favorite Drill',
    path: `/favorites/drills/${id}`,
  },
  payload: {
    entity: 'drills',
  },
})

export const addTrainingPackageToFavorites = id => ({
  types: ADD_TRAINING_PACKAGE_TO_FAVORITES,
  callAPI: {
    method: 'POST',
    entity: 'Favorite Training Package',
    path: `/favorites/training_packages/${id}`,
  },
  payload: {
    entity: 'trainingPackages',
  },
})

export const addVideoToFavorites = id => ({
  types: ADD_VIDEO_TO_FAVORITES,
  callAPI: {
    method: 'POST',
    entity: 'Favorite Video',
    path: `/favorites/videos/${id}`,
  },
  payload: {
    entity: 'videos',
  },
})

export const addSequenceToFavorites = id => ({
  types: ADD_SEQUENCE_TO_FAVORITES,
  callAPI: {
    method: 'POST',
    entity: 'Favorite Sequence',
    path: `/favorites/sequences/${id}`,
  },
  payload: {
    entity: 'sequences',
  },
})

export const deleteArticleFromFavorites = id => ({
  types: DELETE_ARTICLE_FROM_FAVORITES,
  callAPI: {
    method: 'DELETE',
    entity: 'Favorite Article',
    path: `/favorites/articles/${id}`,
  },
  payload: {
    entity: 'articles',
  },
})

export const deleteDrillFromFavorites = id => ({
  types: DELETE_DRILL_FROM_FAVORITES,
  callAPI: {
    method: 'DELETE',
    entity: 'Favorite Drill',
    path: `/favorites/drills/${id}`,
  },
  payload: {
    entity: 'drills',
  },
})

export const deleteTrainingPackageFromFavorites = id => ({
  types: DELETE_TRAINING_PACKAGE_FROM_FAVORITES,
  callAPI: {
    method: 'DELETE',
    entity: 'Favorite Training Package',
    path: `/favorites/training_packages/${id}`,
  },
  payload: {
    entity: 'trainingPackages',
  },
})

export const deleteVideoFromFavorites = id => ({
  types: DELETE_VIDEO_FROM_FAVORITES,
  callAPI: {
    method: 'DELETE',
    entity: 'Favorite Video',
    path: `/favorites/videos/${id}`,
  },
  payload: {
    entity: 'videos',
  },
})

export const deleteSequenceFromFavorites = id => ({
  types: DELETE_SEQUENCE_FROM_FAVORITES,
  callAPI: {
    method: 'DELETE',
    entity: 'Favorite Sequence',
    path: `/favorites/sequences/${id}`,
  },
  payload: {
    entity: 'sequences',
  },
})

export default handleActions(
  {
    [combineActions(
      FETCH_FAVORITES_ARTICLES.request,
      FETCH_FAVORITES_DRILLS.request,
      FETCH_FAVORITES_TRAINING_PACKAGES.request,
      FETCH_FAVORITES_VIDEOS.request,
      FETCH_FAVORITES_SEQUENCES.request,
      ADD_ARTICLE_TO_FAVORITES.request,
      ADD_DRILL_TO_FAVORITES.request,
      ADD_TRAINING_PACKAGE_TO_FAVORITES.request,
      ADD_VIDEO_TO_FAVORITES.request,
      ADD_SEQUENCE_TO_FAVORITES.request,
      DELETE_ARTICLE_FROM_FAVORITES.request,
      DELETE_DRILL_FROM_FAVORITES.request,
      DELETE_TRAINING_PACKAGE_FROM_FAVORITES.request,
      DELETE_VIDEO_FROM_FAVORITES.request,
      DELETE_SEQUENCE_FROM_FAVORITES.request
    )]: onRequest,
    [combineActions(
      FETCH_FAVORITES_ARTICLES.success,
      FETCH_FAVORITES_DRILLS.success,
      FETCH_FAVORITES_TRAINING_PACKAGES.success,
      FETCH_FAVORITES_VIDEOS.success,
      FETCH_FAVORITES_SEQUENCES.success,
      ADD_ARTICLE_TO_FAVORITES.success,
      ADD_DRILL_TO_FAVORITES.success,
      ADD_TRAINING_PACKAGE_TO_FAVORITES.success,
      ADD_VIDEO_TO_FAVORITES.success,
      ADD_SEQUENCE_TO_FAVORITES.success,
      DELETE_ARTICLE_FROM_FAVORITES.success,
      DELETE_DRILL_FROM_FAVORITES.success,
      DELETE_TRAINING_PACKAGE_FROM_FAVORITES.success,
      DELETE_VIDEO_FROM_FAVORITES.success,
      DELETE_SEQUENCE_FROM_FAVORITES.success
    )]: (state, { response: { data = [] }, entity }) =>
      onSuccess({
        ...state,
        items: {
          ...state.items,
          [entity]: data,
        },
      }),
    [combineActions(
      FETCH_FAVORITES_ARTICLES.failure,
      FETCH_FAVORITES_DRILLS.failure,
      FETCH_FAVORITES_TRAINING_PACKAGES.failure,
      FETCH_FAVORITES_VIDEOS.failure,
      FETCH_FAVORITES_SEQUENCES.failure,
      ADD_ARTICLE_TO_FAVORITES.failure,
      ADD_DRILL_TO_FAVORITES.failure,
      ADD_TRAINING_PACKAGE_TO_FAVORITES.failure,
      ADD_VIDEO_TO_FAVORITES.failure,
      ADD_SEQUENCE_TO_FAVORITES.failure,
      DELETE_ARTICLE_FROM_FAVORITES.failure,
      DELETE_DRILL_FROM_FAVORITES.failure,
      DELETE_TRAINING_PACKAGE_FROM_FAVORITES.failure,
      DELETE_VIDEO_FROM_FAVORITES.failure,
      DELETE_SEQUENCE_FROM_FAVORITES.failure
    )]: onFailure,
    TOGGLE_DRILL_FAVORITE: (state, { payload: { id, favorite } }) => {
      let favoriteDrills = [...state.items.drills]

      // add new favorite drill to state
      if (favorite && !Boolean(favoriteDrills.findById(id))) {
        favoriteDrills.push({ id })
      }

      // remove drill from favorite state
      if (!favorite && Boolean(favoriteDrills.findById(id))) {
        favoriteDrills.splice(favoriteDrills.findIndex(function(item) {
          return item.id === id
        }), 1)
      }

      return {
        ...state,
        items: {
          ...state.items,
          drills: favoriteDrills,
        },
      }
    },
  },
  initialState.favorites
)
