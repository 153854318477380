import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { InnrHead } from 'containers'
import { Button, Grid, Typography } from '@material-ui/core'
import Input from 'components/inputes/Input'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'redux'
import createJoiValidator from 'utils/createJoiValidator'
import Joi from 'joi'
import PropTypes from 'prop-types'

const validationSchema = Joi.object().keys({
  title: Joi.string().max(255).required(),
})

const AddLineupDialog = ({ open, handleClose, handleSubmit, lineups }) => {
  const [titleError, setTitleError] = useState(false)

  // Check lineups with same title if existed then show error
  const checkLineupExistance = (value) => {
    const isExist = lineups.some(
      (lineup) => lineup.group === '%TEAM%' && lineup.title === value
    )

    if (isExist) {
      setTitleError(true)
    } else {
      setTitleError(false)
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xs" fullWidth>
      <InnrHead title={<Translate id="matches.save-lineup" />}>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon />
        </IconButton>
      </InnrHead>
      <DialogContent>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item xs={12}>
            <Field
              name="title"
              label={<Translate id="articles.title" />}
              component={Input}
              margin="dense"
              onInput={(e) => checkLineupExistance(e.target.value)}
            />
          </Grid>
          {titleError && (
            <Grid item xs={12}>
              <Typography variant="caption" color="error">
                <Translate id="matches.lineup-title-error" />
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Button color="primary" onClick={handleClose}>
              <Translate id="button.cancel" />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={titleError}
            >
              <Translate id="button.save" />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
AddLineupDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  lineups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default compose(
  connect(({ lineups }) => ({
    lineups: lineups.items,
  })),
  reduxForm({
    form: 'addLineupForm',
    validate: createJoiValidator(validationSchema),
  })
)(AddLineupDialog)
