import { Button, Grid } from '@material-ui/core'
import React from 'react'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'

const LineupEditModeToggler = ({
  isEditMode,
  handleEdit,
  handleCancel,
  handleSave,
}) => {
  return (
    <>
      {!isEditMode ? (
        <Grid container justifyContent="flex-start" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              endIcon={<EditIcon />}
              onClick={handleEdit}
            >
              <Translate id="matches.edit-formation" />
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              <Translate id="button.cancel" />
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSave}>
              <Translate id="button.save" />
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  )
}

LineupEditModeToggler.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
}

export default LineupEditModeToggler
