import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import Rating from '@material-ui/lab/Rating'
import {
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Box,
  Typography,
} from '@material-ui/core'
import filterObject from 'utils/filterObject'
import BallIcon from 'assets/images/ballicon.svg'
import hasSubscription from 'utils/hasSubscription'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TeamEditDialog from './TeamEditDialog'
import { BASIC } from 'constants/plans'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}))

const StyledRating = withStyles((theme) => ({
  root: {
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
}))(Rating)

const TeamSummary = ({ team, matchesRating, club }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [initialValues, setInitialValues] = useState({
    ...filterObject({ ...team }, Boolean),
  })

  const { name, logo } = team

  const classes = useStyles()

  return (
    <Card>
      <CardHeader
        action={
          <IconButton onClick={handleClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        }
      />
      <CardContent>
        <TeamEditDialog
          open={open}
          handleClick={handleClick}
          handleClose={handleClose}
          initialValues={{ ...initialValues, logo }}
          setInitialValues={setInitialValues}
        />

        <Box className={classes.root}>
          <Avatar
            variant="square"
            alt="logo"
            className={classes.large}
            src={logo && logo.full_url ? logo.full_url : BallIcon}
          />
        </Box>
        <Box className={classes.root}>
          <Typography variant="h3">{name}</Typography>
          {hasSubscription() !== BASIC && (
            <StyledRating name="read-only" value={matchesRating} readOnly />
          )}
          {club && club.enable_team_tags && (
            <Typography variant="body2">{team.team_tag_name}</Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}
TeamSummary.propTypes = {
  team: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.shape({
      full_url: PropTypes.string.isRequired,
    }).isRequired,
    team_tag_name: PropTypes.string.isRequired,
  }).isRequired,
  matchesRating: PropTypes.number.isRequired,
  club: PropTypes.shape({
    enable_team_tags: PropTypes.bool.isRequired,
  }).isRequired,
}

export default connect(
  ({ team, clubs }) => ({
    team,
    club: clubs.current,
  }),
  {}
)(TeamSummary)
