import React from 'react'
import MuiPhoneNumber from 'material-ui-phone-number'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'

const InputPhoneNumber = ({
  input,
  ref,
  id,
  name,
  label,
  country,
  meta,
  ...rest
}) => {
  const inputError = meta.touched && meta.error

  return (
    <MuiPhoneNumber
      error={Boolean(inputError)}
      helperText={
        inputError ? <Translate id={`joi.error.${meta.error}`} /> : ''
      }
      id={id}
      label={label}
      type="tel"
      name={name}
      variant="outlined"
      defaultCountry={country ? country.toLowerCase() : 'ch'}
      preferredCountries={['de', 'at', 'ch', 'us', 'gb']}
      margin='dense'
      disableAreaCodes
      localization={{
        Germany: <Translate id="signup.tel-country-Germany" />,
        Switzerland: <Translate id="signup.tel-country-Switzerland" />,
        Austria: <Translate id="signup.tel-country-Austria" />,
        'United States': <Translate id="signup.tel-country-US" />,
        'United Kingdom': <Translate id="signup.tel-country-UK" />,
      }}
      {...input}
      {...rest}
    />
  )
}

InputPhoneNumber.defaultProps = {
  type: 'text',
  meta: {},
  label: '',
  id: 'phone',
  name: 'phone',
}

InputPhoneNumber.propTypes = {
  input: PropTypes.shape().isRequired,
  type: PropTypes.string,
  meta: PropTypes.shape(),
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  ref: PropTypes.string,
  country: PropTypes.string.isRequired,
}
export default InputPhoneNumber
