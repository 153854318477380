import usePermission from './usePermission'

export {
  usePermission,
}

/* eslint-disable */
Array.prototype.updateById = function(id, data) {
  if (typeof data === 'object') {
    return this.map(item => (item.id === id ? { ...item, ...data } : item))
  }

  return this.map(item => (item.id === id ? data : item))
}

Array.prototype.updateByUuid = function(uuid, data) {
  if (typeof data === 'object') {
    return this.map(item => (item.uuid === uuid ? { ...item, ...data } : item))
  }

  return this.map(item => (item.uuid === uuid ? data : item))
}

Array.prototype.findById = function(id) {
  return this.find(item => item.id === id)
}

Array.prototype.findByUuid = function(uuid) {
  return this.find(item => item.uuid === uuid)
}

Array.prototype.filterById = function(id) {
  return this.filter(item => item.id !== id)
}

Array.prototype.filterByUuid = function(uuid) {
  return this.filter(item => item.uuid !== uuid)
}

Array.prototype.findEndGetProp = function(condition, propName) {
  const targetItem = this.find(condition)

  return targetItem && targetItem[propName]
}

String.prototype.populateURLParams = function(params) {
  let newString = this

  Object.entries(params).forEach(([key, value]) => {
    newString = newString.replace(`:${key}`, value)
  })

  return newString
}
