import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

const DraggableLineupPosition = ({
  children,
  positionId,
  positionX,
  positionY,
}) => {
  const [offset, setOffset] = useState({ offsetX: 0, offsetY: 0 })

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: 'field_position',
      item: { positionX, positionY },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [positionX, positionY]
  )

  const draggableBoxStyle = {
    opacity: isDragging ? 0 : 1, // Hide actual state while dragging
  }

  useEffect(() => {
    const element = document.getElementById(positionId)
    if (element) {
      const { width, height } = element.getBoundingClientRect()
      setOffset({ offsetX: width / 2, offsetY: height / 2 })
    }
  }, [positionId])

  useEffect(() => {
    dragPreview(getEmptyImage(), {
      anchorX: offset.offsetX,
      anchorY: offset.offsetY,
    })
  }, [dragPreview])

  return (
    <div ref={drag} style={draggableBoxStyle}>
      {children}
    </div>
  )
}

DraggableLineupPosition.propTypes = {
  children: PropTypes.element.isRequired,
  positionId: PropTypes.string.isRequired,
  positionX: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  positionY: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}

export default DraggableLineupPosition
