import React from 'react'
import PropTypes from 'prop-types'
import { TextField, InputAdornment, IconButton } from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import { Translate } from 'react-localize-redux'

const InputPassword = ({
  input,
  meta = {},
  label,
  placeholder = '',
  size = '',
  variant = '',
  margin = '',
  id = '',
  name = '',
  onChange,
  ...rest
}) => {
  const inputError = meta.touched && meta.error
  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <Translate>
      {({ translate }) => (
        <TextField
          type={showPassword ? 'text' : 'password'}
          label={label}
          variant={variant}
          error={Boolean(inputError)}
          margin={margin}
          helperText={inputError ? translate(`joi.error.${meta.error}`) : ''}
          placeholder={placeholder}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  size="small"
                >
                  {showPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          size={size}
          fullWidth
          id={id}
          name={name}
          disabled={rest.disabled}
          {...input}
          {...rest}
        />
      )}
    </Translate>
  )
}

InputPassword.defaultProps = {
  meta: {},
  placeholder: '',
  size: 'small',
  variant: 'outlined',
  margin: 'none',
  id: '',
  name: '',
  onChange: () => 0,
}

InputPassword.propTypes = {
  meta: PropTypes.shape(),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  margin: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
}

export default InputPassword
