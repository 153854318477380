import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { Box, makeStyles, IconButton } from '@material-ui/core'
import {
  matches as matchesModule,
  lineups as lineupsModule,
} from 'redux/modules'
import FieldBackground from 'assets/images/field.png'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import AlertDialog from 'components/AlertDialog'
import PlayersListPopover from './PlayersListPopover'
import PlayerFieldCard from './PlayerFieldCard'
import LineupEditModeToggler from './LineupEditModeToggler'
import AddLineupDialog from './AddLineupDialog'
import LineupEditMode from './LineupEditMode'

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    position: 'relative',
    height: '100%',
    width: 'auto',
    maxHeight: '80vh'
  },
  fieldImage: {
    display: 'block',
    height: '100%',
    width: 'auto'
  },
  styledPlayer: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  plusIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
    boxShadow: `0px 2px 6px ${theme.palette.grey[500]}`,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 2px 10px ${theme.palette.grey[500]}`,
    },
  },
  validDrop: {
    border: '2px dashed transparent',
    borderColor: theme.palette.success.main, // Color when hovering over drop area
  },
  invalidDrop: {
    border: '2px dashed transparent',
    borderColor: theme.palette.error.main, // Color when hovering outside drop area
  },
}))

const FieldArea = ({
  matchPlayers,
  updateMatchParticipant,
  lineupPositions,
  matchLineup,
  saveMatchLineup,
  updateMatch,
  currentMatchUuid,
  teamUuid,
  updateMatchParticipantsInBulk,
  isEditMode,
  setIsEditMode,
}) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [fieldPositions, setFieldPositions] = useState([])
  const [customFieldPositions, setCustomFieldPositions] = useState([])
  const [selectedPosition, setSelectedPosition] = useState()

  const [isEditModeAlert, setIsEditModeAlert] = useState(false)
  const [addLineupDialogOpen, setAddLineupDialogOpen] = useState(false)

  const handleAddLineupDialogClose = () => {
    setAddLineupDialogOpen(false)
  }

  const handleEditModeConfirm = () => {
    setIsEditModeAlert(false)
    setIsEditMode(true)

    const selectedParticipants = matchPlayers.filter((p) => p.participant)

    if (selectedParticipants.length > 0) {
      // Reset all participants x=null,y=null and participant=false to clear the field
      const matchParticipants = matchPlayers.map((p) => {
        return { ...p, position_x: null, position_y: null, participant: false }
      })

      updateMatchParticipantsInBulk(currentMatchUuid, {
        participants: matchParticipants,
      })
    }
  }

  const handleEditFormationClick = () => {
    // Show clear field popup only when there are players on the field otherwise just go to edit mode directly
    const selectedParticipants = matchPlayers.filter((p) => p.participant)

    if (selectedParticipants.length > 0) {
      setIsEditModeAlert(true)
    } else {
      setIsEditMode(true)
      handleEditModeConfirm()
    }
  }

  const handleCancel = () => {
    setIsEditMode(false)
  }

  const handleSave = () => {
    setAddLineupDialogOpen(true)
  }

  const handlePlusIconClick = (event, position) => {
    setAnchorEl(event.currentTarget)
    setSelectedPosition(position)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handlePlayerSelect = (position, player) => {
    setFieldPositions(
      fieldPositions.map((pos) => {
        if (pos.x === position.x && pos.y === position.y) {
          return { ...pos, player_uuid: player.uuid, player }
        }

        return pos
      })
    )
    setAnchorEl(null)

    updateMatchParticipant(player.matchPlayerUuid, {
      position_x: position.x,
      position_y: position.y,
    })
  }

  const handleClearPosition = (position) => {
    setFieldPositions(
      fieldPositions.map((pos) => {
        const temp = { ...pos }
        if (temp.x === position.x && temp.y === position.y) {
          delete temp.player
        }

        return temp
      })
    )
    updateMatchParticipant(position.uuid, {
      position_x: null,
      position_y: null,
    })
  }

  useEffect(() => {
    if (lineupPositions) {
      setFieldPositions(lineupPositions)
    }
  }, [lineupPositions])

  // useEffect(() => {
  //   const { width } = document
  //     .getElementById('participantsField')
  //     .getBoundingClientRect()

  //   document.getElementById('participantsField').style.height = `${
  //     width * 1.613
  //   }px`
  // }, [])

  const handleSaveLineup = (values) => {
    const requestData = {
      title: values.title,
      positions: customFieldPositions.map((pos) => {
        return { x: +pos.x, y: +pos.y }
      }),
    }

    saveMatchLineup(teamUuid, requestData).then((res) => {
      const { data } = res.response

      handleAddLineupDialogClose()

      // Update match lineup on new custom lineup save.
      // Also with this match lineup will automatically switched to new saved lineup in UI
      updateMatch(currentMatchUuid, {
        lineup_uuid: data.uuid,
        preventToastNotification: true,
      })

      setIsEditMode(false)
    })
  }

  useEffect(() => {
    if (matchLineup.lineup) {
      setCustomFieldPositions(matchLineup.lineup)
    }
  }, [matchLineup])

  return (
    <>
      {addLineupDialogOpen && (
        <AddLineupDialog
          open={addLineupDialogOpen}
          handleClose={handleAddLineupDialogClose}
          onSubmit={handleSaveLineup}
        />
      )}
      {isEditModeAlert && (
        <AlertDialog
          open={isEditModeAlert}
          onClose={() => setIsEditModeAlert(false)}
          onSuccess={handleEditModeConfirm}
          onCancel={() => setIsEditModeAlert(false)}
          title={<Translate id="matches.change-lineup" />}
          subTitle={<Translate id="matches.confirm-lineup-change-message" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
      <LineupEditModeToggler
        isEditMode={isEditMode}
        handleEdit={handleEditFormationClick}
        handleSave={handleSave}
        handleCancel={handleCancel}
      />

      {isEditMode ? (
        <LineupEditMode
          matchLineup={matchLineup}
          customFieldPositions={customFieldPositions}
          setCustomFieldPositions={setCustomFieldPositions}
        />
      ) : (
        <Box 
          // id="participantsField" 
          className={classes.fieldWrapper}
        >
          <img
            src={FieldBackground}
            alt="Pitch/Field"
            className={classes.fieldImage}
          />

          {fieldPositions.map((position) => (
            <Box
              key={`${position.x}${position.y}`}
              className={classes.styledPlayer}
              style={{
                left: `${position.x}%`,
                top: `${position.y}%`,
              }}
            >
              {position.matchPlayer ? (
                <PlayerFieldCard
                  handleClearPosition={handleClearPosition}
                  position={position.matchPlayer}
                />
              ) : (
                <IconButton
                  className={classes.plusIcon}
                  onClick={(event) => handlePlusIconClick(event, position)}
                  aria-describedby={position.id}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          ))}
          {open && selectedPosition && (
            <PlayersListPopover
              open={open}
              players={matchPlayers}
              handlePlayerSelect={handlePlayerSelect}
              handlePopoverClose={handlePopoverClose}
              position={selectedPosition}
              anchorEl={anchorEl}
              handleClose={handlePopoverClose}
            />
          )}
        </Box>
      )}
    </>
  )
}

FieldArea.propTypes = {
  matchPlayers: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  lineupPositions: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  updateMatchParticipant: PropTypes.func.isRequired,
  matchLineup: PropTypes.shape().isRequired,
  saveMatchLineup: PropTypes.func.isRequired,
  updateMatch: PropTypes.func.isRequired,
  currentMatchUuid: PropTypes.string.isRequired,
  teamUuid: PropTypes.string.isRequired,
  updateMatchParticipantsInBulk: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  setIsEditMode: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ matches: { current }, team }) => ({
      currentMatch: current,
      matchPlayers: current.relationships.participants,
      currentMatchUuid: current.uuid,
      teamUuid: team.uuid,
    }),
    {
      shareMatch: matchesModule.shareMatch,
      deleteMatch: matchesModule.deleteMatch,
      updateMatchParticipant: matchesModule.updateMatchParticipant,
      saveMatchLineup: lineupsModule.saveMatchLineup,
      updateMatch: matchesModule.updateMatch,
      updateMatchParticipantsInBulk:
        matchesModule.updateMatchParticipantsInBulk,
    }
  )
)(FieldArea)
