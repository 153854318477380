let userlaneIdentified = false
let userlaneInitialized = false
const userLaneEnabled =
  process.env.REACT_APP_USERLANE_ENABLED === 'true' || false

const userlaneIdentify = (userId, locale, clubId, teamId, paid, country) => {
  if (!userLaneEnabled) {
    return
  }

  if (window.Userlane) {
    if (!userlaneIdentified) {
      // eslint-disable-next-line no-undef
      Userlane('lang', locale)

      // eslint-disable-next-line no-undef
      Userlane('identify', userId, {
        team_id: teamId,
        club_id: clubId,
        paid,
        country,
        device_type: window.innerWidth <= 768 ? 'mobile' : 'desktop',
      })

      userlaneIdentified = true
    }
  } else {
    // eslint-disable-next-line no-console
    console.error('Userlane not loaded.')
  }
}

const userlaneInit = () => {
  if (!userLaneEnabled) {
    return
  }

  if (window.Userlane) {
    if (!userlaneInitialized) {
      // eslint-disable-next-line no-undef
      Userlane('init', process.env.REACT_APP_USERLANE_KEY)

      userlaneInitialized = true
    }
  } else {
    // eslint-disable-next-line no-console
    console.error('Userlane not loaded.')
  }
}

const setUserlaneLanguage = (lang) => {
  if (!userLaneEnabled) {
    return
  }

  if (window.Userlane) {
    // eslint-disable-next-line no-undef
    Userlane('lang', lang)
  }
}

export { userlaneIdentify, userlaneInit, setUserlaneLanguage }
