import React from 'react'
import { DrillsCategoriesLoader, AgeClassesLoader } from 'loaders'
import ActivityCreateContainer from '../../containers/ActivityCreateContainer'
import PROP_TYPES from 'constants/propTypes'

const ActivityCreatePage = ({ history, match, location }) => (
  <DrillsCategoriesLoader>
    <AgeClassesLoader>
      <ActivityCreateContainer
        history={history}
        match={match}
        location={location}
      />
    </AgeClassesLoader>
  </DrillsCategoriesLoader>
)

ActivityCreatePage.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PROP_TYPES.match.isRequired,
  location: PROP_TYPES.location.isRequired,
}

export default ActivityCreatePage
