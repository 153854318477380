import React from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'

const CustomAutocomplete = ({
  input,
  meta: { touched, error },
  options,
  label,
}) => {
  const selectedOption = options.find((opt) => opt.title === input.value) || {
    title: input.value || '',
  }

  return (
    <Autocomplete
      freeSolo
      options={options}
      getOptionLabel={(option) => option.title || ''}
      value={selectedOption}
      onChange={(event, newValue) => {
        const newTitle =
          typeof newValue === 'string' ? newValue : newValue?.title
        input.onChange(newTitle || '')
      }}
      onInputChange={(event, newInputValue) => {
        input.onChange(newInputValue)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size='small'
          variant="outlined"
          error={touched && !!error}
          helperText={touched && error ? error : null}
        />
      )}
    />
  )
}

CustomAutocomplete.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  label: PropTypes.string.isRequired,
}

export default CustomAutocomplete
