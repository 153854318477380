import React, { useState, useEffect } from 'react'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import getVideoId from 'get-video-id'
import { Translate } from 'react-localize-redux'
import { useStyles } from '../styles'
import fallbackImageUrl from '../../../assets/images/login-coach.jpg'
import { StyledToggleButtonGroup, StyledToggleButton } from './StyledComponents'

const MediaTabSection = ({ drill }) => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState('video')
  const hasImage = drill.relationships.images.length > 0
  const hasVideo = drill.video_url && drill.video_url !== ''

  useEffect(() => {
    if (!hasVideo) {
      setSelectedTab('image')
    }
  }, [hasVideo])

  const handleTabChange = (event, newValue) => {
    if (newValue) {
      setSelectedTab(newValue)
    }
  }

  const getVideoUrl = (url) => {
    const { service, id } = getVideoId(url)
    const baseUrl =
      service === 'youtube'
        ? 'https://www.youtube.com/embed/'
        : 'https://player.vimeo.com/video/'

    return `${baseUrl}${id}`
  }

  return (
    <Box className={classes.contentContainer}>
      {hasVideo && (
        <Box display="flex" justifyContent="center" mb={2}>
          <StyledToggleButtonGroup
            value={selectedTab}
            exclusive
            onChange={handleTabChange}
            aria-label="media type"
          >
            <StyledToggleButton value="image" aria-label="image">
              <Translate id="drills.drawing" />
            </StyledToggleButton>
            <StyledToggleButton value="video" aria-label="video">
              <Translate id="drills.video" />
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </Box>
      )}
      <Box className={classes.imageContainer}>
        {selectedTab === 'image' && (
          <Box className={classes.imageContainer}>
            <img
              src={
                hasImage
                  ? drill.relationships.images[
                      drill.relationships.images.length - 1
                    ].full_url
                  : fallbackImageUrl
              }
              alt="Drill"
              className={classes.image}
            />
          </Box>
        )}
        {selectedTab === 'video' && hasVideo && (
          <Box className={classes.videoContainer}>
            <iframe
              title="coachbetter Video"
              src={getVideoUrl(drill.video_url)}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className={classes.iframe}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

MediaTabSection.defaultProps = {
  drill: {
    relationships: {
      images: [],
    },
    video_url: null,
  },
}

MediaTabSection.propTypes = {
  drill: PropTypes.shape({
    relationships: PropTypes.shape({
      images: PropTypes.arrayOf(
        PropTypes.shape({
          full_url: PropTypes.string,
        })
      ).isRequired,
    }).isRequired,
    video_url: PropTypes.string,
  }),
}

export default MediaTabSection
