import React from 'react'
import PropTypes from 'prop-types'
import { TextField, InputAdornment } from '@material-ui/core'
import { Translate } from 'react-localize-redux'

const Input = ({
  input,
  type = 'text',
  meta = {},
  label,
  placeholder = '',
  size = '',
  variant = '',
  margin = '',
  id = '',
  name = '',
  endadornment,
  startadornment,
  onChange,
  ...rest
}) => {
  const inputError = meta.touched && meta.error

  return (
    <Translate>
      {({ translate }) => (
        <TextField
          type={type}
          label={label}
          variant={variant}
          error={Boolean(inputError)}
          margin={margin}
          helperText={inputError ? translate(`joi.error.${meta.error}`) : ''}
          placeholder={placeholder}
          onChange={onChange}
          InputProps={
            startadornment ||
            (endadornment && {
              startAdornment: (
                <InputAdornment position="start">
                  {startadornment}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">{endadornment}</InputAdornment>
              ),
            })
          }
          size={size}
          fullWidth
          id={id}
          name={name}
          disabled={rest.disabled}
          {...input}
          {...rest}
        />
      )}
    </Translate>
  )
}

Input.defaultProps = {
  type: 'text',
  meta: {},
  label: '',
  placeholder: '',
  size: 'small',
  variant: 'outlined',
  margin: 'none',
  id: '',
  name: '',
  onChange: () => 0,
  startadornment: null,
  endadornment: null,
}

Input.propTypes = {
  input: PropTypes.shape().isRequired,
  type: PropTypes.string,
  meta: PropTypes.shape(),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  placeholder: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  margin: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  endadornment: PropTypes.string,
  startadornment: PropTypes.string,
  onChange: PropTypes.func,
}

export default Input
