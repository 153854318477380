import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import {
  Card,
  CardContent,
  Box,
  CardHeader,
  Typography,
  makeStyles,
  Button,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import AlertDialog from 'components/AlertDialog'
import { lineups as lineupsModule, matches } from 'redux/modules'
import FieldArea from './FieldArea'
import LineupsFilter from './LineupsFilter'
import PlayersListsContainer from './PlayersListsContainer'
import AddLineupDialog from './AddLineupDialog'

const useStyles = makeStyles((theme) => ({
  cardContent: {
    backgroundColor: theme.palette.grey[100],
  },
  cardHeader: {
    '& .MuiCardHeader-action': {
      alignSelf: 'end',
    },
  },
  positionRelative: {
    position: 'relative',
  },
  gridOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.grey[100],
    opacity: '0.5',
    zIndex: 999,
  },
}))

const LineUpContainer = ({
  lineups,
  participants,
  matchLineup,
  saveMatchLineup,
  updateMatch,
  currentMatchUuid,
  teamUuid,
}) => {
  const classes = useStyles()
  const [lineupPositions, setLineupPositions] = useState([])
  const [addLineupDialogOpen, setAddLineupDialogOpen] = useState(false)
  const [isEmptyLineup, setIsEmptyLineup] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const handleAddLineupDialog = () => {
    const participantsOnField = participants.filter((p) => p.participant)
    if (participantsOnField.length === 0) {
      setIsEmptyLineup(true)

      return
    }

    setAddLineupDialogOpen(true)
  }

  const handleAddLineupDialogClose = () => {
    setAddLineupDialogOpen(false)
  }

  useEffect(() => {
    if (participants && matchLineup) {
      let tempPostions = []

      let tempLineup = lineups.find((l) => l.uuid === matchLineup.uuid)

      // #important
      // If tempLineup empty means match lineup is a saved lineup and its deleted by coach or management
      // But it will not affect matches which has this lineup selected so will assign it and use its positions
      if (!tempLineup && matchLineup.deleted_at) {
        tempLineup = matchLineup
      }

      tempPostions = tempLineup.lineup.map((position) => {
        const matchingPlayers = participants.filter(
          (p) =>
            (position.x === `${p.position_x}` &&
              position.y === `${p.position_y}`) || // Compared as string because lineup contains positions as string and not float
            // Player should be in saved lineup and participant in match to render on the field
            (position.player_uuid === p.player_uuid && p.participant)
        )

        if (matchingPlayers.length > 0) {
          return {
            ...position,
            matchPlayer: matchingPlayers[0], // Array contains only one matching player
          }
        }

        return {
          x: position.x,
          y: position.y,
          player_uuid: position.player_uuid,
        }
      })
      setLineupPositions(tempPostions)
    }
  }, [participants, matchLineup])

  const handleSaveLineup = (values) => {
    saveMatchLineup(teamUuid, {
      match_uuid: currentMatchUuid,
      title: values.title,
    }).then((res) => {
      const { data } = res.response

      handleAddLineupDialogClose()

      // Update match lineup on new custom lineup save.
      // Also with this match lineup will automatically switched to new saved lineup in UI
      updateMatch(currentMatchUuid, {
        lineup_uuid: data.uuid,
        preventToastNotification: true,
      })
    })
  }

  return (
    <Card>
      {isEmptyLineup && (
        <AlertDialog
          open={isEmptyLineup}
          onClose={() => setIsEmptyLineup(false)}
          onSuccess={() => setIsEmptyLineup(false)}
          onCancel={() => setIsEmptyLineup(false)}
          title={<Translate id="matches.save-lineup" />}
          subTitle={<Translate id="matches.empty-lineup-save-alert" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
      {addLineupDialogOpen && (
        <AddLineupDialog
          open={addLineupDialogOpen}
          handleClose={handleAddLineupDialogClose}
          onSubmit={handleSaveLineup}
        />
      )}
      <Box className={classes.positionRelative}>
        <CardHeader
          className={classes.cardHeader}
          action={
            <Grid container alignItems="center">
              <Button
                onClick={handleAddLineupDialog}
                variant="contained"
                color="primary"
              >
                <Typography variant="inherit">
                  <Translate id="matches.save-lineup" />
                </Typography>
              </Button>
            </Grid>
          }
          title={
            <LineupsFilter
              lineups={lineups}
              lineupPositions={lineupPositions}
              setLineupPositions={setLineupPositions}
            />
          }
        />
        {isEditMode && <Box className={classes.gridOverlay} />}
      </Box>
      <CardContent component={Box} m={0} p={0} className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} container justifyContent="center">
            <FieldArea
              lineupPositions={lineupPositions}
              matchLineup={matchLineup}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
          </Grid>
          <Grid item md={6} xs={12} className={classes.positionRelative}>
            {isEditMode && <Box className={classes.gridOverlay} />}
            <PlayersListsContainer players={participants} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

LineUpContainer.propTypes = {
  lineups: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  participants: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  matchLineup: PropTypes.shape().isRequired,
  saveMatchLineup: PropTypes.func.isRequired,
  updateMatch: PropTypes.func.isRequired,
  currentMatchUuid: PropTypes.func.isRequired,
  teamUuid: PropTypes.string.isRequired,
}

export default compose(
  connect(
    ({ matches: { current }, lineups: { items }, team }) => ({
      participants: current.relationships.participants,
      matchLineup: current.relationships.lineup,
      lineups: items,
      currentMatchUuid: current.uuid,
      teamUuid: team.uuid,
    }),
    {
      saveMatchLineup: lineupsModule.saveMatchLineup,
      updateMatch: matches.updateMatch,
    }
  )
)(LineUpContainer)
