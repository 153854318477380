import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { formateDate,formatUtcDateToDateTime } from 'utils/formatDate'
import {
  CardHeader,
  Typography,
  Button,
  List,
  ListItem,
  Divider,
  Box,
  Paper,
  Grid,
} from '@material-ui/core'
import hasSubscription from 'utils/hasSubscription'
import PropTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import eventsIcon from 'assets/images/events@1x.svg'
import { SubLink, ArticlesWidget, DrillsWidget } from 'containers'
import { getClub } from 'utils/localstorage'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import EventPopup from 'components/material/Calender/eventPopup'
import {
  trainings,
  matches,
  generalEvents,
  teams as teamsModule,
} from 'redux/modules'
import { LoadingSpinner, Ticker } from 'components'
import TaskGroupsWidget from 'containers/TaskManagement/TaskGroupsWidget'
import AllPlayers from 'containers/TeamDashboard/components/AllPlayers'
import { EVENT_ROUTE } from 'constants/routes'
import { DEFAULT_CLUB_ID } from 'constants/club'
import { TRAININGS_GAME } from 'constants/calender'
import PERMISSIONS from 'constants/permissions'
import PROP_TYPES from 'constants/propTypes'
import { BASIC } from 'constants/plans'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    '& .MuiButton-root': {
      fontWeight: 600,
    },
  },
  paper: {
    height: '100%',
  },
  sublink: {
    marginRight: theme.spacing(1.9),
  },
  customPrimary: {},
  freeTrialCard: {
    height: 257,
    textAlign: 'center',
  },

  LinearProgress: {
    height: 8,
    borderRadius: 8,
  },
  nextEventList: {
    height: 174,

    padding: '0 ',
    '& .MuiListItem-root': {
      margin: theme.spacing(1.2, 0),
      borderLeft: '3px solid ',
    },
    '& .MuiListItem-root:last-child': {
      marginBottom: '0',
      border: '0',
    },
  },
  errorMessage: {
    marginLeft: 15,
  },
}))

const HomeDashboardContainers = ({
  team,
  createTraining,
  createMatch,
  createEvent,
  scheduleTrainings,
  match,
  drills,
  articles,
  teamPermissions,
  history,
  fetchTeamTickers,
  fetchTeamNextEvents,
  seasons,
  timezone,
}) => {
  const classes = useStyles()
  const [seasonUuid, setSeasonUuid] = useState(null)
  const [nextTraining, setNextTraining] = useState(null)
  const [nextMatch, setNextMatch] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const availableTrainingPlayers =
    nextTraining?.relationships?.participants.filter(
      (player) => player.availability === 'AVAILABLE'
    ).length

  const totalTrainingPlayers =
    nextTraining?.relationships?.participants.length || 0

  const availableMatchPlayers = nextMatch?.relationships?.participants.filter(
    (player) => player.availability === 'AVAILABLE'
  ).length

  const totalMatchPlayers = nextMatch?.relationships?.participants.length || 0
  // @todo: #tasks remove
  const canManageTaskGroups = teamPermissions.includes(
    PERMISSIONS.MANAGE_TASK_GROUPS
  )
  const {
    params: { teamId, seasonId },
  } = match

  const { players, age_class: ageClass } = team

  const nextMatchDateFormate = nextMatch
    ? formateDate(nextMatch.start_datetime)
    : null

  const nextTrainingDateFormate = nextTraining
    ? formateDate(nextTraining.start_datetime)
    : null

  const [open, setOpen] = useState(false)
  const [trainingAndGame, setTrainingAndGame] = useState('')

  const handleClose = () => {
    setOpen(false)
  }

  const newCreateTraining = (data) => {
    createTraining(0, data).then((res) => {
      const { uuid } = {
        ...res.response.data,
      }
      handleClose()
      history.push(`/team/${teamId}/season/${seasonId}/trainings/${uuid}`)
    })
  }

  const newCreateMatch = (data) => {
    createMatch(seasonUuid, data).then((res) => {
      const { uuid } = {
        ...res.response.data,
      }
      handleClose()
      if (hasSubscription() === BASIC) {
        history.push(`/team/${teamId}/season/${seasonId}/events`)
      } else {
        history.push(`/team/${teamId}/season/${seasonId}/matches/${uuid}`)
      }
    })
  }
  const newCreateEvent = (data) => {
    createEvent(seasonUuid, data).then((res) => {
      const { uuid } = {
        ...res.response.data,
      }
      handleClose()
      if (hasSubscription() === BASIC) {
        history.push(`/team/${teamId}/season/${seasonId}/events`)
      } else {
        history.push(
          `/team/${teamId}/season/${seasonId}/general-events/${uuid}`
        )
      }
    })
  }
  const newScheduleTrainings = (data) => {
    scheduleTrainings(data).then(() => {
      handleClose()
      history.push(`/team/${teamId}/season/${seasonId}/events`)
    })
  }
  const isOwnClubArticles = articles.filter((art) => {
    return art.club_id === getClub()?.id
  })
  const isOwnClubDrills = drills.filter((drl) => {
    return drl.club_id === getClub()?.id
  })
  const splitDrills =
    getClub().id !== DEFAULT_CLUB_ID && getClub().display_coachbetter_drills
  const splitArticles =
    getClub().id !== DEFAULT_CLUB_ID && getClub().display_coachbetter_articles

  const handleEventCreate = (gameType) => {
    setTrainingAndGame(gameType)
    setOpen(true)
  }

  // @todo Once season uuid can be accessed from url this can be removed
  useEffect(() => {
    if (seasons) {
      const season = seasons.find((s) => s.id === +seasonId)
      setSeasonUuid(season.uuid)
    }
  }, [seasons])

  useEffect(() => {
    setIsLoading(true)
    setError(null)
    if (seasonUuid) {
      fetchTeamNextEvents(seasonUuid)
        .then((res) => {
          setNextMatch(res.response.data.next_match)
          setNextTraining(res.response.data.next_training)
        })
        .catch((error) => {
          console.error('Error fetching next events:', error)
          setError(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [fetchTeamNextEvents, seasonUuid])

  return (
    <>
      {open && (
        <EventPopup
          open={open}
          onClose={handleClose}
          trainingAndGame={trainingAndGame}
          createTraining={newCreateTraining}
          createMatch={newCreateMatch}
          scheduleTrainings={newScheduleTrainings}
          createEvent={newCreateEvent}
          calenderDateSelected={false}
          calenderDate={null}
          history={history}
        />
      )}

      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <Paper className={classes.paper}>
              <CardHeader
                title={
                  <Typography variant="h4" component="h4">
                    <Translate id="home.next-event" />
                  </Typography>
                }
                action={
                  !isLoading &&
                  !error && (
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => handleEventCreate(TRAININGS_GAME.training)}
                    >
                      <Translate id="button.plan-event" />
                    </Button>
                  )
                }
              />
              {isLoading && <LoadingSpinner />}
              {error && (
                <Box className={classes.errorMessage}>
                  Something went wrong!
                </Box>
              )}
              {!isLoading && !error && (
                <List className={classes.nextEventList}>
                  <ListItem>
                    {nextTraining ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="between"
                        alignItems="center"
                        textAlign="left"
                      >
                        <Box component="div" mr={3}>
                          <Typography variant="h4" component="h4">
                            {
                              formatUtcDateToDateTime(
                                nextTraining.start_datetime,
                                timezone
                              )[1]
                            }{' '}
                          </Typography>
                          <Typography variant="h6" fontSize={14} component="h6">
                            {`${nextTrainingDateFormate[4]} ${nextTrainingDateFormate[3]}`}
                          </Typography>
                        </Box>
                        <Box component="div">
                          <SubLink to={`/trainings/${nextTraining.uuid}`}>
                            <Typography variant="h4" component="h4">
                              <Translate id="home.trainings" />
                            </Typography>
                            <Typography variant="caption" component="h6">
                              {`${
                                nextTraining.short_address
                                  ? nextTraining.short_address
                                  : ''
                              }`}
                            </Typography>
                          </SubLink>
                        </Box>
                        <Box
                          component="div"
                          ml="auto"
                          textAlign="right"
                          color="text.primary"
                        >
                          <Typography variant="h4" component="h4">
                            {`${availableTrainingPlayers}/${totalTrainingPlayers}`}
                          </Typography>
                          <Typography variant="h6" fontSize={14} component="h6">
                            <Translate id="home.available" />{' '}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <SubLink to={EVENT_ROUTE}>
                        <Box
                          width="100%"
                          mr={1}
                          display="flex"
                          alignItems="center"
                          textAlign="left"
                        >
                          <img src={eventsIcon} alt={eventsIcon} />
                          <Typography variant="p" component="p">
                            <Translate id="home.no-training-events-planned" />
                          </Typography>
                        </Box>
                      </SubLink>
                    )}
                  </ListItem>
                  <Divider />
                  <ListItem>
                    {nextMatch ? (
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="between"
                        alignItems="center"
                        textAlign="left"
                      >
                        <Box component="div" mr={3}>
                          <Typography variant="h4" component="h4">
                            {
                              formatUtcDateToDateTime(
                                nextMatch.start_datetime,
                                timezone
                              )[1]
                            }
                          </Typography>
                          <Typography variant="h6" fontSize={14} component="h6">
                            {`${nextMatchDateFormate[4]} ${nextMatchDateFormate[3]}`}
                          </Typography>
                        </Box>
                        <Box component="div">
                          <SubLink
                            to={
                              hasSubscription() === BASIC
                                ? EVENT_ROUTE
                                : `/matches/${nextMatch.uuid}`
                            }
                          >
                            <Typography variant="h4" component="h4">
                              {`${nextMatch.opponent
                                .charAt(0)
                                .toUpperCase()}${nextMatch.opponent.slice(1)}`}
                            </Typography>
                            <Typography variant="caption" component="h6">
                              {`${
                                nextMatch.short_address
                                  ? `${
                                      nextMatch.home === 0 ? 'Away' : 'Home'
                                    } - ${nextMatch.city || nextMatch.country}`
                                  : `${nextMatch.home === 0 ? 'Away' : 'Home'}`
                              }`}
                            </Typography>
                          </SubLink>
                        </Box>
                        <Box
                          component="div"
                          ml="auto"
                          textAlign="right"
                          color="text.primary"
                        >
                          <Typography variant="h4" component="h4">
                            {`${availableMatchPlayers}/${totalMatchPlayers}`}
                          </Typography>
                          <Typography variant="h6" fontSize={14} component="h6">
                            <Translate id="player.available" />{' '}
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <SubLink to={EVENT_ROUTE}>
                        <Box
                          width="100%"
                          mr={1}
                          display="flex"
                          alignItems="center"
                          textAlign="left"
                        >
                          <img src={eventsIcon} alt={eventsIcon} />
                          <Typography variant="p" component="p">
                            <Translate id="home.no-match-events-planned" />
                          </Typography>
                        </Box>
                      </SubLink>
                    )}
                  </ListItem>
                </List>
              )}
            </Paper>
          </Grid>
          {!!team.add_players_enabled && (
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <Translate>
                {({ translate }) => (
                  <AllPlayers
                    players={players.filter(
                      (player) => player.status === 'ACTIVE'
                    )}
                    title={translate('team.whole-team')}
                  />
                )}
              </Translate>
            </Grid>
          )}
          {canManageTaskGroups && hasSubscription() !== BASIC && (
            <Grid item xs={12} md={6} lg={3} xl={3}>
              <TaskGroupsWidget />
            </Grid>
          )}
          <Grid item xs={12} md={6} lg={3} xl={3}>
            <Ticker loader={() => fetchTeamTickers(team.uuid)} />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {splitDrills ? (
            <>
              {isOwnClubDrills.length > 0 && (
                <Grid item xs={12} md={12} lg={6} xl={6}>
                  <Paper className={classes.paper}>
                    <DrillsWidget ageClass={ageClass} clubId={getClub()?.id} />
                  </Paper>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={12}
                lg={isOwnClubDrills.length > 0 ? 6 : 12}
                xl={isOwnClubDrills.length > 0 ? 6 : 12}
              >
                <Paper className={classes.paper}>
                  <DrillsWidget ageClass={ageClass} clubId={DEFAULT_CLUB_ID} />
                </Paper>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Paper className={classes.paper}>
                <DrillsWidget ageClass={ageClass} clubId={getClub()?.id} />
              </Paper>
            </Grid>
          )}
          {splitArticles ? (
            <>
              {isOwnClubArticles.length > 0 && (
                <Grid item xs={12} md={12} lg={6} xl={6}>
                  <Paper className={classes.paper}>
                    <ArticlesWidget clubid={getClub()?.id} />
                  </Paper>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                md={12}
                lg={isOwnClubArticles.length > 0 ? 6 : 12}
                xl={isOwnClubArticles.length > 0 ? 6 : 12}
              >
                <Paper className={classes.paper}>
                  <ArticlesWidget clubid={DEFAULT_CLUB_ID} />
                </Paper>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Paper className={classes.paper}>
                <ArticlesWidget clubid={getClub()?.id} />
              </Paper>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  )
}

HomeDashboardContainers.propTypes = {
  history: PROP_TYPES.history.isRequired,
  match: PropTypes.shape().isRequired,
  createTraining: PropTypes.func.isRequired,
  createMatch: PropTypes.func.isRequired,
  scheduleTrainings: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  teamPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  team: PropTypes.shape().isRequired,
  seasons: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  articles: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  drills: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  fetchTeamTickers: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ auth, drills, articles, team, seasons: { items } }) => ({
      drills: drills.items,
      articles: articles.items,
      team,
      teamPermissions:
        auth.permissions.teams.find((item) => item.id === team.id)
          ?.permissions ?? [],
      seasons: items[team.id],
      timezone: auth.user.time_zone,
    }),
    {
      createTraining: trainings.createTraining,
      createMatch: matches.createMatch,
      scheduleTrainings: trainings.scheduleOnlyTrainings,
      createEvent: generalEvents.createEvent,
      fetchTeamTickers: teamsModule.fetchTeamTickers,
      fetchTeamNextEvents: teamsModule.fetchTeamNextEvents,
    }
  )
)(HomeDashboardContainers)
