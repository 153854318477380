import React, { useEffect, useMemo, useState } from 'react'
import { Translate } from 'react-localize-redux'
import {
  Select,
  MenuItem,
  InputLabel,
  Box,
  FormControl,
  Grid,
  Typography,
  makeStyles,
  IconButton,
  ListSubheader,
} from '@material-ui/core'
import { connect } from 'react-redux'
import { matches, lineups as lineupsModule } from 'redux/modules'
import AlertDialog from 'components/AlertDialog'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  linupSelect: {
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: ' translate(14px, -6px) scale(0.75)',
      background: theme.palette.common.white,
      padding: theme.spacing(0, 1),
    },
  },
  deleteIconButton: {
    padding: 0,
  },
  dropdownItemsWrapper: {
    maxHeight: '500px',
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `1px 1px 12px 0 ${theme.palette.grey[400]}`,
    '& .MuiListSubheader-root': {
      fontWeight: 'bold',
    },
    '& .MuiListItem-root': {
      paddingLeft: theme.spacing(4),
    },
  },
}))

const LineupsFilter = ({
  lineups,
  currentMatch,
  lineupPositions,
  updateMatch,
  updateMatchParticipantsInBulk,
  participants,
  matchLineup,
  deleteLineup,
}) => {
  const classes = useStyles()
  const [activeLineupUuid, setActiveLineupUuid] = useState(null)
  const [changeLineupAlertOpen, setChangeLineupAlertOpen] = useState(false)
  const [selectedLineupUuid, setSelectedLineupUuid] = useState()
  const [lineupDeleteAlertOpen, setLineupDeleteAlertOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const groupedLineups = useMemo(() => {
    // Logic to group all the lineups by their groups
    if (lineups.length) {
      return lineups.reduce((groupedData, lineup) => {
        const { group } = lineup

        // Check if the group already exists in the group data
        const groupIndex = groupedData.findIndex((item) => item.name === group)

        if (groupIndex === -1) {
          // If the group doesn't exist, add it to the group data
          groupedData.push({ name: group, lineups: [lineup] })
        } else {
          // If the group already exists, push the lineup to its lineups array
          groupedData[groupIndex].lineups.push(lineup)
        }

        return groupedData
      }, [])
    }

    // Return an empty array if lineups.length === 0
    return []
  }, [lineups]) // Recalculates whenever lineups changes

  const handleLineupChangeSubmit = (uuid) => {
    if (!uuid) {
      return
    }

    // Reset all participants x=null,y=null and participant=false
    let matchParticipants = participants.map((p) => {
      return { ...p, position_x: null, position_y: null, participant: false }
    })

    // Now get the selected lineup
    const selectedLineup = lineups.find((l) => l.uuid === uuid)
    setActiveLineupUuid(selectedLineup.uuid)

    // Bind the participants according to the lineup positions if had player_uuid
    selectedLineup.lineup.forEach((p) => {
      matchParticipants = matchParticipants.map((mp) => {
        if (mp.player_uuid === p.player_uuid) {
          return {
            ...mp,
            position_x: p.x,
            position_y: p.y,
            participant: true,
          }
        }

        return mp
      })
    })

    updateMatch(currentMatch.uuid, {
      lineup_uuid: selectedLineup.uuid,
      preventToastNotification: true,
    })

    // Close lineups menu
    setMenuOpen(false)

    // Only update participants in bulk if they are on the field otherwise do nothing
    if (matchParticipants.length > 0) {
      updateMatchParticipantsInBulk(currentMatch.uuid, {
        participants: matchParticipants.map((p) => {
          return {
            uuid: p.uuid,
            position_x: p.position_x,
            position_y: p.position_y,
          }
        }),
      })
    }
  }

  const handleLineupChange = (uuid) => {
    // Need to be updated as per team participants
    const selectedParticipants = participants.filter((p) => p.participant)
    if (selectedParticipants.length > 0) {
      setChangeLineupAlertOpen(true)
      setSelectedLineupUuid(uuid)
    } else {
      handleLineupChangeSubmit(uuid)
    }
  }

  const handleAlertClose = () => {
    setChangeLineupAlertOpen(false)
    setSelectedLineupUuid(null)
  }

  const handleAlertConfirm = () => {
    handleLineupChangeSubmit(selectedLineupUuid)
    handleAlertClose()
    setSelectedLineupUuid(null)
  }

  useEffect(() => {
    if (matchLineup) {
      setActiveLineupUuid(matchLineup.uuid)
    }
  }, [matchLineup])

  const handleDeleteClick = (uuid, event) => {
    event.stopPropagation()
    setLineupDeleteAlertOpen(true)
    setSelectedLineupUuid(uuid)
  }
  const handleDeleteAlertConfirm = () => {
    setLineupDeleteAlertOpen(false)
    deleteLineup(selectedLineupUuid)
    setSelectedLineupUuid(null)
  }
  const handleDeleteAlertClose = () => {
    setLineupDeleteAlertOpen(false)
    setSelectedLineupUuid(null)
  }

  const getDeletedLineupTranslatedLabel = (title) => {
    return (
      <>
        (<Translate id="matches.deleted" />) {title}
      </>
    )
  }

  return (
    <>
      {lineupDeleteAlertOpen && (
        <AlertDialog
          open={lineupDeleteAlertOpen}
          onClose={handleDeleteAlertClose}
          onSuccess={handleDeleteAlertConfirm}
          onCancel={handleDeleteAlertClose}
          title={<Translate id="button.delete" />}
          subTitle={<Translate id="events.are-you-sure-you-want-to-delete" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
      <Box>
        <Grid container>
          <Grid xs={10} md={3}>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.linupSelect}
            >
              <InputLabel labelId="match-lineup-filter-label">
                <Translate id="matches.line-ups-text" />
              </InputLabel>
              <Select
                labelId="match-lineup-filter-label"
                id="match-lineup-filter"
                value={activeLineupUuid}
                label={<Translate id="matches.line-ups-text" />}
                renderValue={(selected) => {
                  const selectedLineup = lineups.find(
                    (l) => l.uuid === selected
                  )

                  return selectedLineup
                    ? selectedLineup.title
                    : getDeletedLineupTranslatedLabel(matchLineup.title)
                }}
                MenuProps={{
                  classes: { paper: classes.dropdownItemsWrapper }, // Apply custom styles to the menu,
                }}
                // On Change method not working after list items grouping so will manage dorpdown open state manually
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                onOpen={() => setMenuOpen(true)}
              >
                {groupedLineups.map((group) => (
                  <>
                    <ListSubheader disableSticky color="primary">
                      {group.name === '%TEAM%' ? (
                        <Translate id="matches.saved-lineups" />
                      ) : (
                        group.name
                      )}
                    </ListSubheader>
                    {group.lineups.map((lineup) => (
                      <MenuItem
                        value={lineup.uuid}
                        key={lineup.uuid}
                        onClick={() => handleLineupChange(lineup.uuid)}
                      >
                        <Grid
                          container
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {lineup.title}
                          {lineup.can.includes('delete') && (
                            <IconButton
                              className={classes.deleteIconButton}
                              onClick={(event) =>
                                handleDeleteClick(lineup.uuid, event)
                              }
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          )}
                        </Grid>
                      </MenuItem>
                    ))}
                  </>
                ))}
              </Select>
            </FormControl>
            {!lineupPositions?.length && (
              <Typography variant="body2">
                <Translate id="matches.no-available-players-message" />
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      {changeLineupAlertOpen && (
        <AlertDialog
          open={changeLineupAlertOpen}
          onClose={handleAlertClose}
          onSuccess={handleAlertConfirm}
          onCancel={handleAlertClose}
          title={<Translate id="matches.change-lineup" />}
          subTitle={<Translate id="matches.confirm-lineup-change-message" />}
          submitButtonLabel={<Translate id="button.ok" />}
          cancelButtonLabel={<Translate id="button.close" />}
        />
      )}
    </>
  )
}

LineupsFilter.defaultProps = {
  lineups: [],
}

LineupsFilter.propTypes = {
  lineups: PropTypes.arrayOf(PropTypes.shape().isRequired),
  lineupPositions: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  currentMatch: PropTypes.shape().isRequired,
  updateMatch: PropTypes.func.isRequired,
  updateMatchParticipantsInBulk: PropTypes.func.isRequired,
  participants: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  matchLineup: PropTypes.shape({
    title: PropTypes.string.isRequired,
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  deleteLineup: PropTypes.func.isRequired,
}

export default connect(
  ({ matches: { current } }) => ({
    currentMatch: current,
    participants: current.relationships.participants,
    matchLineup: current.relationships.lineup,
  }),
  {
    updateMatch: matches.updateMatch,
    updateMatchParticipantsInBulk: matches.updateMatchParticipantsInBulk,
    deleteLineup: lineupsModule.deleteLineup,
  }
)(LineupsFilter)
