import React, { useCallback, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { Box, makeStyles, IconButton } from '@material-ui/core'
import { matches as matchesModule } from 'redux/modules'
import FieldBackground from 'assets/images/field.png'
import PropTypes from 'prop-types'
import { useDragLayer, useDrop } from 'react-dnd'
import DraggableLineupPosition from 'components/MatchDashboard/components/MatchLineUp/LineupEditMode/DraggableLineupPosition'
import CustomDragLayer from './CustomDragLayer'
import GridOverlay from './GridOverlay'

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    position: 'relative',
    height: '100%',
    width: 'auto',
    maxHeight: '80vh'
  },
  fieldImage: {
    display: 'block',
    height: '100%',
    width: 'auto'
  },
  styledPlayer: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  plusIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
    boxShadow: `0px 2px 6px ${theme.palette.grey[500]}`,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 2px 10px ${theme.palette.grey[500]}`,
    },
  },
  validDrop: {
    border: '2px dashed transparent',
    borderColor: theme.palette.success.main, // Color when hovering over drop area
  },
  invalidDrop: {
    border: '2px dashed transparent',
    borderColor: theme.palette.error.main, // Color when hovering outside drop area
  },
}))

const LineupEditMode = ({ customFieldPositions, setCustomFieldPositions }) => {
  const classes = useStyles()

  // useEffect(() => {
  //   const { width } = document
  //     .getElementById('participantsField')
  //     .getBoundingClientRect()

  //   document.getElementById('participantsField').style.height = `${
  //     width * 1.613
  //   }px`
  // }, [])

  // Calculate the relative positions of players with respect to the field area
  const calculateRelativePosition = (position, fieldPosition) => ({
    position_x:
      Math.round(
        ((position.x - fieldPosition.x) / fieldPosition.width) * 10000
      ) / 100,
    position_y:
      Math.round(
        ((position.y - fieldPosition.y) / fieldPosition.height) * 10000
      ) / 100,
  })

  // Function calculate the nearest grid value (x,y) to place the player or empty slot
  const snapToGrid = (x, y, gridSize) => {
    const snappedX = Math.round(x / gridSize) * gridSize
    const snappedY = Math.round(y / gridSize) * gridSize

    return [snappedX, snappedY]
  }

  // Handle player or empty slot drop
  const handlePlayerDrop = useCallback((x, y, newPosition) => {
    const participantPosition = calculateRelativePosition(
      newPosition,
      document.getElementById('pitch').getBoundingClientRect()
    )

    const gridSize =
      parseInt(process.env.REACT_APP_PLAYER_DROPPABLE_GRID_SIZE, 10) || 5

    // Snap to nearest grid
    const [snappedX, snappedY] = snapToGrid(
      participantPosition.position_x,
      participantPosition.position_y,
      gridSize // Grid size in percentage (e.g., snap to 5% increments)
    )

    // Ensure snapped positions stay within bounds and avoid edges
    const finalX = Math.max(gridSize, Math.min(100 - gridSize, snappedX))
    const finalY = Math.max(gridSize, Math.min(100 - gridSize, snappedY))

    // Update position as per drop
    setCustomFieldPositions(
      customFieldPositions.map((pos) => {
        if (pos.x === x && pos.y === y) {
          return {
            ...pos,
            x: finalX,
            y: finalY,
          }
        }

        return pos
      })
    )
  })

  // useDragLayer hook is used to track the dragging state
  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
  }))

  // useDrop hook is used to control the drop related information
  const [{ isOver }, drop] = useDrop({
    accept: 'field_position',
    drop: (item, monitor) => {
      const clientOffset = monitor.getClientOffset()
      const { positionX, positionY } = item

      handlePlayerDrop(positionX, positionY, clientOffset)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  })

  return (
    <>
      <Box
        id="pitch"
        ref={drop}
        className={`${classes.fieldWrapper} ${
          isDragging && !isOver ? classes.invalidDrop : classes.validDrop
        }`}
      >
        <img
          src={FieldBackground}
          alt="Pitch/Field"
          className={classes.fieldImage}
        />

        {/* Render the GridOverlay here */}
        <GridOverlay />

        {/* CustomDragLayer to customise the dragging state of element */}
        <CustomDragLayer isOver={isOver} />

        {customFieldPositions.map((position) => (
          <Box
            key={`${position.x}${position.y}`}
            className={classes.styledPlayer}
            style={{
              left: `${position.x}%`,
              top: `${position.y}%`,
            }}
          >
            <DraggableLineupPosition
              positionId={`${position.x}${position.y}`}
              positionX={position.x}
              positionY={position.y}
            >
              <IconButton className={classes.plusIcon}>
                <AddIcon fontSize="small" />
              </IconButton>
            </DraggableLineupPosition>
          </Box>
        ))}
      </Box>
    </>
  )
}

LineupEditMode.propTypes = {
  customFieldPositions: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  setCustomFieldPositions: PropTypes.func.isRequired,
}

export default compose(
  withRouter,
  connect(
    ({ matches: { current } }) => ({
      currentMatch: current,
      matchPlayers: current.relationships.participants,
    }),
    {
      shareMatch: matchesModule.shareMatch,
      deleteMatch: matchesModule.deleteMatch,
      updateMatchParticipant: matchesModule.updateMatchParticipant,
    }
  )
)(LineupEditMode)
