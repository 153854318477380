import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none', // Ensure that the overlay does not interfere with dragging
  },
  circle: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper, // Color of the circles
    borderRadius: '50%',
    width: '5px', // Width of the circle
    height: '5px', // Height of the circle
    transform: 'translate(-50%, -50%)', // Center the circle at the intersection
    opacity: '0.8',
  },
  line: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    opacity: '0.2',
  },
  horizontalLine: {
    width: '100%',
    height: '1px',
  },
  verticalLine: {
    width: '1px',
    height: '100%',
  },
}))

const GridOverlay = () => {
  const classes = useStyles()

  // Get grid size from environment, fallback to 5 if not set
  const gridSize =
    parseInt(process.env.REACT_APP_PLAYER_DROPPABLE_GRID_SIZE, 10) || 5

  const elements = []

  // Adjust loops to avoid edges by starting and ending within bounds
  for (let i = 1; i < 100 / gridSize; i++) {
    for (let j = 1; j < 100 / gridSize; j++) {
      elements.push(
        <div
          key={`circle-${i}-${j}`}
          className={classes.circle}
          style={{
            left: `${i * gridSize}%`,
            top: `${j * gridSize}%`,
          }}
        />
      )
    }
  }

  for (let i = 0; i <= 100 / gridSize; i++) {
    // Add horizontal lines spanning the entire width
    elements.push(
      <div
        key={`h-line-${i}`}
        className={`${classes.line} ${classes.horizontalLine}`}
        style={{
          top: `${i * gridSize}%`,
        }}
      />
    )

    // Add vertical lines spanning the entire height
    elements.push(
      <div
        key={`v-line-${i}`}
        className={`${classes.line} ${classes.verticalLine}`}
        style={{
          left: `${i * gridSize}%`,
        }}
      />
    )
  }

  return <div className={classes.overlay}>{elements}</div>
}

export default GridOverlay
