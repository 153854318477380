import { Paper, Button, Typography, Toolbar } from '@material-ui/core'
import React, { useMemo } from "react"
import { Translate } from 'react-localize-redux'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { DrillCard } from 'components'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { SubLink } from 'containers'
import { Carousel } from 'components'
import CarouselItem from 'components/material/CarouselItem'
import { getClub } from 'utils/localstorage'
import { ACTIVITIES_ROUTE } from 'constants/routes'
import { DEFAULT_CLUB_ID } from 'constants/club'
import PropTypes from 'prop-types'
import { updateFavoriteDrill } from './api'
import { TOGGLE_DRILL_FAVORITE } from "../../../redux/modules/favorites"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0, 3, 3),
    '& .MuiBox-root': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  toolbar: {
    minHeight: 54,
  },
  descriptionCell: {
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 20px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  media: {
    height: 140,
  },
  title: {
    flex: '1 1 0',
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(-3),
    right: theme.spacing(2),
  },
}))

const DrillsWidgetContainer = ({
  activities,
  favoritesDrills,
  clubId,
  language,
  dispatch,
}) => {
  const classes = useStyles()

  // filter all drills by specified club ID and set favorite value
  const filterAndMapDrills = (activities, favoritesDrills, clubId) => {
    return activities
      .filter(drill => drill.club_id === clubId)
      .map(drill => {
        return {
          ...drill,
          favorite: Boolean(favoritesDrills.findById(drill.id))
        }
      })
  }

  const drills = useMemo(() => filterAndMapDrills(activities, favoritesDrills, clubId), [activities, favoritesDrills, clubId]);

  const handleFavoriteToggle = (uuid) => {
    const drill = drills.findByUuid(uuid)

    updateFavoriteDrill(uuid, !drill.favorite)
      .then(() => {
        dispatch({ type: TOGGLE_DRILL_FAVORITE, payload: { id: drill.id, favorite: !drill.favorite } })
      })
      .catch((error) => {
        console.error('Error toggling favorite status:', error)
      })
  }

  return (
    <Paper className={classes.root}>
      <Toolbar disableGutters="false" className={classes.toolbar}>
        <Typography variant="h4" component="h4" className={classes.title}>
          {clubId === DEFAULT_CLUB_ID ? (
            <Translate id="home.coachbetter_drills" />
          ) : (
            <>
              {`${getClub()?.name}`} <Translate id="home.drills" />
            </>
          )}
        </Typography>
        <Button
          color="primary"
          component={SubLink}
          to={ACTIVITIES_ROUTE}
          endIcon={<ChevronRightIcon />}
        >
          <Translate id="home.read-more" />
        </Button>
      </Toolbar>
      <Carousel>
        {drills.map(
          ({
            uuid,
            title,
            images,
            duration,
            max_players,
            min_players,
            favorite,
            coaching_points,
            drill_flow,
            description,
            organization,
            variations,
            video_url,
            age_classes,
          }) =>
            <CarouselItem key={uuid}>
              <DrillCard
                withPhaseMenu={false}
                showAddToTrainingButton={false}
                showDrillActions={false}
                onFavoriteToggle={() => handleFavoriteToggle(uuid)}
                drill={{
                  uuid: uuid,
                  title: title,
                  relationships: {
                    images: images || [],
                    age_classes: age_classes || [],
                  },
                  duration: duration,
                  max_players: max_players,
                  min_players: min_players,
                  variations: variations,
                  organization: organization,
                  description: description,
                  coaching_points: coaching_points,
                  drill_flow: drill_flow,
                  video_url: video_url,
                  favorite: favorite,
                  can: [],
                }}
                language={language}
                onPhaseSelect={ () => {} }
                onDuplicate={ () => {} }
              />
            </CarouselItem>
        )}
      </Carousel>
    </Paper>
  )
}

DrillsWidgetContainer.propTypes = {
  activities: PropTypes.array,
  favoritesDrills: PropTypes.array,
  language: PropTypes.string.isRequired,
  clubId: PropTypes.number.isRequired,
}

export default connect(
  ({ drills, favorites, auth }) => ({
    activities: drills.items,
    language: auth.user.language,
    favoritesDrills: favorites.items.drills,
  })
)(DrillsWidgetContainer)
