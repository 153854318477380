import React, { useEffect, useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import { Box, Grid, Button } from '@material-ui/core'
import PROP_TYPES from 'constants/propTypes'

const LanguageSelector = ({
  languages,
  selectedLanguage,
  setSelectedLanguage,
}) => {
  const [langs, setLangs] = useState([])

  const handleLanguageChange = (tempLang) => {
    setLangs(
      langs.map((p) => {
        const lang = { ...p }
        if (lang.value === tempLang) {
          lang.active = true
        } else {
          lang.active = false
        }

        return lang
      })
    )
    setSelectedLanguage(tempLang)
  }

  useEffect(() => {
    setLangs(
      languages.map((p) => {
        const lang = { ...p }

        return {
          value: lang.code,
          title: lang.name,
          active: lang.code === selectedLanguage,
        }
      })
    )
  }, [languages])

  return (
    <Grid container>
      {langs.map(({ value, title, active }) => (
        <Box key={value} m={1}>
          <Button
            variant="contained"
            color={active ? 'primary' : 'default'}
            onClick={() => handleLanguageChange(value)}
          >
            {title}
          </Button>
        </Box>
      ))}
    </Grid>
  )
}

LanguageSelector.propTypes = {
  languages: PROP_TYPES.arrayOfObjects.isRequired,
  selectedLanguage: PROP_TYPES.string.isRequired,
  setSelectedLanguage: PROP_TYPES.func.isRequired,
}

export default withLocalize(LanguageSelector)
