// Component is used to add a custom drag preview while dragging the player or slot

import React from 'react'
import { useDragLayer } from 'react-dnd'
import AddIcon from '@material-ui/icons/Add'
import { IconButton, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import PlayerFieldCard from '../PlayerFieldCard'

const useStyles = makeStyles((theme) => ({
  plusIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
    boxShadow: `0px 2px 6px ${theme.palette.grey[500]}`,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 2px 10px ${theme.palette.grey[500]}`,
    },
  },
  layerStyles: {
    position: 'fixed',
    display: 'flex',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
}))

const CustomDragLayer = ({ isOver }) => {
  const classes = useStyles()

  const { isDragging, item, initialOffset, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  )

  function getItemStyles(tempInitialOffset, tempCurrentOffset) {
    if (!tempInitialOffset || !tempCurrentOffset) {
      return {
        display: 'none',
      }
    }
    const { x, y } = currentOffset
    const transform = `translate(${x}px, ${y}px)`

    return {
      transform,
      WebkitTransform: transform,
      opacity: !isOver ? 0.5 : 1, // Adjust the opacity when dragging outside
    }
  }

  function renderItem() {
    if (item.player) {
      return <PlayerFieldCard position={item.player} />
    }

    return (
      <IconButton className={classes.plusIcon}>
        <AddIcon fontSize="small" />
      </IconButton>
    )
  }

  if (!isDragging) {
    return null
  }

  return (
    <div className={classes.layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        {renderItem()}
      </div>
    </div>
  )
}

CustomDragLayer.propTypes = {
  isOver: PropTypes.bool.isRequired,
}

export default CustomDragLayer
