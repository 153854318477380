import React from 'react'
import { Translate } from 'react-localize-redux'
import PlayerActions from './Players/Actions'
import PlayerAvatarImage from './Players/AvatarImage'
import StaffActions from './Staffs/Actions'
import StaffAvatarImage from './Staffs/AvatarImage'
import StaffEmailClickable from './Staffs/StaffEmailClickable'

export const TAB_OPTIONS = {
  players: 'PLAYERS',
  staffs: 'STAFFS'
}

export const SORT_NAME = [{
  field: 'firstName',
  sort: 'asc',
},]

export const playerColumns = [

  { field: 'uuid', headerName: 'UUID', hide: true },
  {
    field: 'firstName',
    headerName: <Translate id="userRoles.player-name" />,
    headerClassName: 'primary',
    width: 350,
    editable: false,
    renderCell: PlayerAvatarImage,
  },
  {
    field: 'email',
    headerName: <Translate id="userRoles.user-email" />,
    headerAlign: "left",
    headerClassName: 'primary',
    align: "left",
    width: 350,
  },
  {
    field: 'position',
    headerName: <Translate id="userRoles.role" />,
    headerClassName: 'primary',
    width: 250,
    editable: false,
  },
  {
    field: 'actions',
    headerName: <Translate id="season.actions" />,
    headerClassName: 'primary',
    width: 180,
    headerAlign: "left",
    align: "left",
    renderCell: PlayerActions,
    disableClickEventBubbling: true,
    sortable: false,
  },

];

export const staffColumns = [

  { field: 'id', headerName: 'ID', hide: true },
  { field: 'uuid', headerName: 'UUID', hide: true },
  { field: 'first_name', headerName: 'First Name', hide: true, editable: false},
  { field: 'last_name', headerName: 'Last Name', hide: true, editable: false},
  {
    field: 'firstName',
    headerName: <Translate id="userRoles.staff-name" />,
    headerClassName: 'primary',
    width: 350,
    editable: false,
    renderCell: StaffAvatarImage,
  },
  {
    field: 'email',
    headerName: <Translate id="userRoles.user-email" />,
    headerAlign: "left",
    headerClassName: 'primary',
    align: "left",
    width: 350,
    renderCell: StaffEmailClickable,
  },
  {
    field: 'position',
    headerName: <Translate id="userRoles.function" />,
    headerClassName: 'primary',
    width: 250,
    editable: false,
  },
  {
    field: 'role',
    headerName: <Translate id="userRoles.role" />,
    headerClassName: 'primary',
    width: 250,
    editable: false,
  },
  {
    field: 'actions',
    headerName: <Translate id="season.actions" />,
    headerClassName: 'primary',
    width: 180,
    headerAlign: "left",
    align: "left",
    renderCell: StaffActions,
    disableClickEventBubbling: true,
    sortable: false,
  },
  {
    field: 'handleClick',
    headerName: "",
    headerClassName: 'primary',
    disableClickEventBubbling: true,
    sortable: false,
    hide: true,
  },

];



