import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import CreateTeamForm from 'containers/CreateTeamForm'
import CircularSpinner from 'components/loader/CircularSpinner'
import { team, teams as teamsModule, clubs as clubsModule } from 'redux/modules'
import PROP_TYPES from 'constants/propTypes'

const CreateTeamPopup = ({
  open,
  handleClose,
  selectedClubUuid,
  createTeamByClub,
  ageClasses,
  fetchTeamsByParams,
  setTeams,
  setFilteredTeams,
  commonFetchParams,
  selectedAgeClassFilter,
  selectedClub,
  setClubs,
  setSelectedAgeClassFilter,
  setSelectedTagFilter,
  fetchClubs,
}) => {
  const [loader, setLoader] = useState(false)

  const handleSubmit = (values) => {
    setLoader(true)

    const selectedAgeClassUuid = values.age_class_uuid
    const selectedTagName = values.tag
    let selectedTagUuid = selectedTagName

    createTeamByClub(selectedClubUuid, values)
      .then(() => {
        if (selectedClubUuid) {
          const filters = {
            ...commonFetchParams,
            'filter[club.uuid]': selectedClubUuid,
          }

          fetchClubs({ include: ['ageClasses', 'teamTags'] }).then((res) => {
            setClubs(res.response.data)

            const selectedClubForTag = res.response.data.find(
              (club) => club.uuid === selectedClubUuid
            )

            selectedTagUuid = selectedClubForTag.relationships.team_tags.find(
              (tag) => tag.name === selectedTagName
            )?.uuid

            if (selectedTagUuid) {
              filters['filter[teamTag.uuid]'] = selectedTagUuid
            }

            if (selectedAgeClassFilter) {
              filters['filter[ageClass.uuid]'] = selectedAgeClassFilter
            } else {
              filters['filter[ageClass.uuid]'] = selectedAgeClassUuid
            }

            setSelectedAgeClassFilter(selectedAgeClassUuid)
            setSelectedTagFilter(selectedTagUuid)

            fetchTeamsByParams(filters)
              .then((fetchTeamsRes) => {
                setTeams(fetchTeamsRes.response)
                setFilteredTeams(fetchTeamsRes.response)
                setLoader(false)
              })
              .catch((error) => {
                console.error('Error fetching teams', error)
              })
          })

          setLoader(false)
          handleClose()
        }
      })
      .catch((error) => {
        setLoader(false)
        toast(error.error)
        handleClose()
      })
  }

  return (
    <div>
      {loader ? (
        <CircularSpinner />
      ) : (
        <Translate>
          {({ translate }) => (
            <CreateTeamForm
              form="createTeamForm"
              initialValues={{
                name: '',
                age_class_uuid: '',
                gender: 'm',
                tag: '',
              }}
              title={translate('myteams.create-team')}
              open={open}
              onClose={handleClose}
              onSubmit={handleSubmit}
              ageClasses={ageClasses}
              selectedClub={selectedClub}
            />
          )}
        </Translate>
      )}
    </div>
  )
}

CreateTeamPopup.defaultProps = {
  open: false,
  selectedClubUuid: null,
}

CreateTeamPopup.propTypes = {
  handleClose: PROP_TYPES.func.isRequired,
  open: PROP_TYPES.bool,
  selectedClubUuid: PropTypes.string,
  createTeamByClub: PropTypes.func.isRequired,
  ageClasses: PropTypes.arrayOf([PropTypes.string]).isRequired,
  fetchTeamsByParams: PropTypes.func.isRequired,
  setTeams: PropTypes.func.isRequired,
  setFilteredTeams: PropTypes.func.isRequired,
  commonFetchParams: PropTypes.shape().isRequired,
  selectedAgeClassFilter: PropTypes.string,
  selectedClub: PropTypes.shape(),
  setClubs: PropTypes.func.isRequired,
  setSelectedAgeClassFilter: PropTypes.func.isRequired,
  setSelectedTagFilter: PropTypes.func.isRequired,
  fetchClubs: PropTypes.func.isRequired,
}

export default connect(null, {
  createTeamByClub: team.createTeamByClub,
  fetchClubs: clubsModule.fetchClubs,
  fetchTeamsByParams: teamsModule.fetchTeamsByParams,
})(CreateTeamPopup)
