import React from 'react'
import { withLocalize } from 'react-localize-redux'
import { setLocale } from 'utils/localstorage'
import { Box, Grid, Button } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import HasClubPermission from './Permissions/HasClubPermission'
import PROP_TYPES from 'constants/propTypes'
import PERMISSIONS from 'constants/permissions'

const ActiveButton = styled(Button)(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[300],
  color: active ? theme.palette.common.white : theme.palette.common.black,
  '&:hover': {
    backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[400],
  },
}));

const LanguageSelectorForAdmins = ({ languages, setActiveLanguage }) => (
  <HasClubPermission ability={PERMISSIONS.TRANSLATE}>
    <Grid container>
      {languages.map(({ code, name, active }) => (
        <Box key={code} m={1}>
          <ActiveButton
            variant="contained"
            active={active}
            onClick={() => {
              setActiveLanguage(code)
              setLocale(code)
            }}
          >
            {name}
          </ActiveButton>
        </Box>
      ))}
    </Grid>
  </HasClubPermission>
)

LanguageSelectorForAdmins.propTypes = {
  languages: PROP_TYPES.arrayOfObjects.isRequired,
  setActiveLanguage: PROP_TYPES.func.isRequired,
}

export default withLocalize(LanguageSelectorForAdmins)
