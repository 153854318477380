import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles, Typography } from '@material-ui/core'
import AccordionItem from 'components/AccordionItem'
import { Translate } from 'react-localize-redux'

const accordions = [
  { key: 'drill_flow', title: 'drills.drill-flow', defaultOpen: true },
  { key: 'organization', title: 'drills.organization' },
  { key: 'coaching_points', title: 'drills.coaching-points' },
  { key: 'variations', title: 'drills.variations' },
]

const useStyles = makeStyles(() => ({
  descriptionHeader: {
    backgroundColor: '#f5f5f5',
    padding: '14px 10px',
    borderRadius: '8px',
  },
}))

const DrillDetailsSection = ({ drill }) => {
  const classes = useStyles()

  return (
    <>
      {drill.description && (
        <>
          <Box className={classes.descriptionHeader}>
            <Typography variant="h4">
              <Translate id="drills.description" />
            </Typography>
          </Box>
          <Box ml={0.5} mb={2.5}>
            <Typography variant="h5" component="h5">
              <div dangerouslySetInnerHTML={{ __html: drill.description }} />
            </Typography>
          </Box>
        </>
      )}
      {accordions.map(({ key, title, defaultOpen }) =>
        drill[key] ? (
          <AccordionItem
            key={key}
            defaultOpen={defaultOpen}
            title={<Translate id={title} />}
          >
            <div dangerouslySetInnerHTML={{ __html: drill[key] }} />
          </AccordionItem>
        ) : null
      )}
    </>
  )
}

DrillDetailsSection.propTypes = {
  drill: PropTypes.shape({
    description: PropTypes.string,
    drill_flow: PropTypes.string,
    organization: PropTypes.string,
    coaching_points: PropTypes.string,
    variations: PropTypes.string,
  }).isRequired,
}

export default DrillDetailsSection
