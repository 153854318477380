import {
  postRequest,
} from 'utils/axios'

export const updateFavoriteDrill = (drillUuid, favorite) => {
  const url = `v2/drills/${drillUuid}/favorite`
  const data = { favorite }

  return postRequest(url, data)
}

