import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, MenuItem } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import Joi from 'joi'
import createJoiValidator from 'utils/createJoiValidator'
import { Field, reduxForm } from 'redux-form'
import { Input } from 'components'
import SelectList from 'components/inputes/SelectList'
import InputPhoneNumber from 'components/inputes/InputPhoneNumber'
import getTranslatedLabelForFormField from 'utils/getTranslatedLabelForFormField'
import useStyles from '../../styleComponents'

const validationSchema = Joi.object().keys({
  teamName: Joi.string()
    .max(255)
    .required()
    .label(getTranslatedLabelForFormField('signup.club_name')),
  ageClass: Joi.string()
    .required()
    .label(getTranslatedLabelForFormField('signup.age_group')),
  phone: Joi.string()
    .required()
    .regex(
      /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/
    ) // @tofix Still need to verify if it will work for all countries
    .label(getTranslatedLabelForFormField('team.phone-number')),
})

const TeamCreateForm = ({
  ageClasses,
  country,
  inProgress,
  theme,
  handleSubmit,
}) => {
  const classes = useStyles(theme)
  const phoneFields = useRef()

  return (
    <Translate>
      {({ translate }) => (
        <Box>
          <Field
            component={Input}
            label={translate('signup.team_name')}
            name="teamName"
            required
            margin="dense"
          />
          <Field
            name="ageClass"
            component={SelectList}
            label={translate('signup.age_group')}
            id="ageClass"
            required
          >
            {ageClasses &&
              ageClasses.map((ac) => (
                <MenuItem key={ac.uuid} value={ac.uuid}>
                  {ac.name}
                </MenuItem>
              ))}
          </Field>
          <Field
            component={InputPhoneNumber}
            name="phone"
            label={translate('team.phone-number')}
            ref={phoneFields}
            country={country}
            required
          />
          <Box mt={1}>
            <Button
              onClick={handleSubmit}
              disabled={inProgress}
              fullWidth
              variant="contained"
              type="button"
              size="large"
              className={classes.button}
            >
              <Translate id="signup.signup-btn" />
            </Button>
          </Box>
        </Box>
      )}
    </Translate>
  )
}
TeamCreateForm.defaultProps = {
  theme: {},
  country: 'CH',
}

TeamCreateForm.propTypes = {
  ageClasses: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  country: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  theme: PropTypes.shape(),
}

export default reduxForm({
  form: 'teamCreateForm',
  validate: createJoiValidator(validationSchema),
})(TeamCreateForm)
