import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Dialog,
  Typography,
  Hidden,
  IconButton,
  Box,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Translate } from 'react-localize-redux'
import { useStyles } from '../styles'
import PhaseMenu from './PhaseMenu'
import { DialogTitle } from './StyledComponents'
import DrillBody from './DrillBody'

const DrillViewDialog = ({
  drill,
  open,
  hanldeOpenClose,
  withPhaseMenu,
  handlePhaseSelect,
}) => {
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={hanldeOpenClose} fullWidth maxWidth="xl">
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h3">{drill.title}</Typography>
        <IconButton aria-label="close" size="small" onClick={hanldeOpenClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Grid container className={classes.dialogContainer}>
        <Hidden only={['xs', 'sm']}>
          <Grid item md={8}>
            <Typography>
              <Translate id="myteams.age-group" />:
              {drill.relationships.age_classes
                .map((ageClass) => ageClass.name)
                .join(',')}
              | <Translate id="drills.players" />:{' '}
              {drill.max_players > 0
                ? `${drill.min_players} - ${drill.max_players}`
                : `min ${drill.min_players}`}{' '}
              | <Translate id="drills.duration" />:{' '}
              {typeof drill.duration === 'number' &&
                drill.duration !== null &&
                `${drill.duration} min`}
            </Typography>
          </Grid>
        </Hidden>
      </Grid>
      <DrillBody drill={drill} />
      {withPhaseMenu && (
        <Box className={classes.phaseMenuBox}>
          <PhaseMenu withText onPhaseSelect={handlePhaseSelect} />
        </Box>
      )}
    </Dialog>
  )
}

DrillViewDialog.defaultProps = {
  drill: {
    min_players: 0,
    duration: null,
  },
  withPhaseMenu: false,
}
DrillViewDialog.propTypes = {
  drill: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    relationships: PropTypes.shape({
      age_classes: PropTypes.arrayOf(
        PropTypes.shape({
          uuid: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
    }),
    duration: PropTypes.number,
    min_players: PropTypes.number,
    max_players: PropTypes.number.isRequired,
  }),
  open: PropTypes.bool.isRequired,
  hanldeOpenClose: PropTypes.func.isRequired,
  withPhaseMenu: PropTypes.bool,
  handlePhaseSelect: PropTypes.func.isRequired,
}

export default DrillViewDialog
